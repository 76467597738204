import { Component, Input } from '@angular/core';
import {
	ProvidersSearchResults,
	SearchEntity,
} from 'src/app/modules/main-layout/care-costs/helpers/providers-search.helper';
import { ProviderGender, ProviderLocation } from 'src/app/modules/main-layout/care-costs/helpers/providers.helpers';

@Component({
	selector: 'app-providers-search-results-print',
	templateUrl: './providers-search-results-print.component.html',
	styleUrls: ['./providers-search-results-print.component.scss'],
})
export class ProvidersSearchResultsPrintComponent {
	@Input({ required: true }) providers: ProvidersSearchResults;
	@Input({ required: true }) entityType: SearchEntity;

	SearchEntity = SearchEntity;
	ProviderGender = ProviderGender;
	date = new Date().toLocaleDateString();

	getMPILocation(locations: ProviderLocation[]) {
		return locations?.find((location) => !!location.insuranceGroup);
	}
}

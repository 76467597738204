import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationExtras } from '@angular/router';
import { T } from '@transifex/angular';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NavController, ToastController } from '@ionic/angular';

import CFG from '../config/app-config.json';

import {
	isIonic,
	extractActivatedRouteData,
	removeSegmentsFromRoute,
	getUrlWoParams,
	isHostMPI,
	isAppMPI,
} from '../utils/utils';

import {
	InactivityDialogAction,
	InactivityDialogComponent,
} from '../dialogs/inactivity-dialog/inactivity-dialog.component';
import { PLAN_SELECTION_ONLY_PATH, PLAN_SELECTION_PATH } from '../modules/main-layout/main-layout.constants';
import { HealtheeDialogData } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { HealtheeDialogService } from 'src/app/services/healthee-dialog.service';

export enum AppViewMode {
	Mobile,
	Desktop,
}
export const DISABLED_BACK_NAVIGATION = '';

@Injectable({
	providedIn: 'root',
})
export class UIService {
	private _appViewMode$: BehaviorSubject<AppViewMode> = new BehaviorSubject<AppViewMode>(AppViewMode.Desktop);
	private _showScreenOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _activatedRouteData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private _route$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public useFederatedLogin: boolean;

	public headerHeight: string = '70px';
	public PCTOnlyMode = false;
	public PCTOnlyMode$ = new BehaviorSubject<boolean>(null);
	public isMPIMode = isHostMPI();
	public isMPIAppMode = isAppMPI();

	@T('Ok')
	private notifications_defaultDismissButton: string;

	constructor(
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public toastController: ToastController,
		private navCtrl: NavController,
		private dialogService: HealtheeDialogService
	) {
		this.subscribeToActivatedRouteData();
		this.subscribeToRoute();
		if (isIonic()) {
			this.headerHeight = '29px';
		}
	}

	public setPCTOnlyModeIfNeeded(isPctOnlyMode: boolean) {
		this.PCTOnlyMode = isPctOnlyMode;
		this.PCTOnlyMode$.next(isPctOnlyMode);
	}

	public setCognitoLoginMode() {
		this.useFederatedLogin = true;
	}

	get appViewMode$() {
		return this._appViewMode$.asObservable();
	}

	get showScreenOverlay$() {
		return this._showScreenOverlay$.asObservable();
	}

	get activatedRouteData$() {
		return this._activatedRouteData$.asObservable();
	}

	get route$() {
		return this._route$.asObservable();
	}

	get isMobile$(): Observable<boolean> {
		return this.appViewMode$.pipe(map((mode) => mode === AppViewMode.Mobile));
	}

	get isMobile(): boolean {
		return this._appViewMode$.value === AppViewMode.Mobile;
	}

	public setAppViewMode(mode: AppViewMode) {
		this._appViewMode$.next(mode);
	}

	// TODO: Change to be consistant with other observables
	public showScreenOverlay() {
		this._showScreenOverlay$.next(true);
	}

	public hideScreenOverlay() {
		this._showScreenOverlay$.next(false);
	}

	private subscribeToActivatedRouteData() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => extractActivatedRouteData(this.activatedRoute))
			)
			.subscribe((data) => this._activatedRouteData$.next(data));
	}

	public updateAppViewMode(targetedWindow: Window) {
		if (UIService.isTabletDeviceScreen(targetedWindow)) {
			this.setAppViewMode(AppViewMode.Mobile);
		} else {
			this.setAppViewMode(AppViewMode.Desktop);
		}
	}

	/** Displays a session is inactive message, with options to "stay connected" or logout
	 */
	public askToStayConnectedOrDisconnect(): Observable<boolean> {
		const dialogData: HealtheeDialogData = {
			component: InactivityDialogComponent,
			componentOnly: true,
			noPadding: true,
			hasCloseButton: true,
			data: {},
		};

		return this.dialogService.open(dialogData).pipe(map((result: InactivityDialogAction) => result?.shouldLogout));
	}

	// TODO: Refactor options into an options object (vs function arguments)
	public displayAppMessage(
		message: string,
		closeButtonText: string = this.notifications_defaultDismissButton,
		ionicIcon: string = undefined,
		verticalPosition: MatSnackBarVerticalPosition = 'top',
		duration: number = CFG.ui.appNotificationDisplayDuration,
		panelClass: string = 'healthee-snackbar'
	) {
		const horizontalPosition: MatSnackBarHorizontalPosition = 'center';

		if (isIonic()) {
			const ionicPosition = verticalPosition === 'top' ? 'top' : 'bottom';
			return this.displayIonicToast(message, closeButtonText, ionicIcon, ionicPosition, duration);
		}

		this.snackBar.open(message, closeButtonText, {
			horizontalPosition,
			verticalPosition,
			duration,
			panelClass,
		});
	}

	private async displayIonicToast(
		message: string,
		closeButtonText: string,
		ionicIcon: string = null,
		position: 'top' | 'bottom' = 'bottom',
		duration: number = 500
	) {
		if (!isIonic()) return;

		const toast = await this.toastController.create({
			message,
			icon: ionicIcon,
			duration,
			position,
			buttons: [closeButtonText],
			cssClass: 'healthee-snackbar-ionic',
		});
		return toast.present();
	}

	/**
	 * isMobileDeviceScreen - a static method to determine if running on a mobile device.
	 * For backward compatibility with legacy code, until a UI service with application state is introduced.
	 * Set breakpoints inside app-config.json file.
	 * @param  {Window} container
	 */
	public static isTabletDeviceScreen(container: Window) {
		//TODO: Fix this -> check also that width higher than mobile view
		return container.innerWidth <= CFG.ui.maxWidthForTablet;
	}

	/**
	 * isMobileDeviceScreen - a static method to determine if running on a mobile device.
	 * For backward compatibility with legacy code, until a UI service with application state is introduced.
	 * Set breakpoints inside app-config.json file.
	 * @param  {Window} container
	 */
	public static isMobileDeviceScreen(container: Window) {
		return container.innerWidth <= CFG.ui.maxWidthForMobile;
	}

	public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		if (isIonic()) {
			return this.navCtrl.navigateForward(commands, extras);
		}

		return this.router.navigate(commands, extras);
	}

	public navigateByUrl(url: string): Promise<boolean> {
		if (isIonic()) {
			// TODO: Change to use the ionic router after parsing the params with getParamsFromUrl and injecting them as extras in navCtrl
			return this.navCtrl.navigateForward('/' + url);
		}

		return this.router.navigateByUrl(url);
	}

	public mobileGoBack(): void {
		if (isIonic()) {
			// TODO: Change to use the ionic router after parsing the params with getParamsFromUrl and injecting them as extras in navCtrl
			return this.navCtrl.back();
		}

		// not supported on web
	}

	public navigateBack(url: string): Promise<boolean> {
		if (isIonic()) {
			return this.navCtrl.navigateBack(url);
		}

		return this.router.navigateByUrl(url);
	}

	/** Navigates one level up using the full URL
	 * Similar to router.navigate(['../']) or [routerLink]="'../'",
	 * but added because relative navigation doesn't work with ionic in the current
	 */
	public navigateOnLevelUp() {
		this.goBackAndNavigateForwardWithFullUrl();
	}

	/** Navigates forward using full URL
	 * added because relative navigation doesn't work with ionic in the current
	 */
	public navigateForwardWithFullUrl(routeSegment: string) {
		const newUrl = this.router.url + `/` + routeSegment;

		if (!isIonic()) {
			this.navCtrl.navigateForward([newUrl]);
			return;
		}

		this.router.navigate([newUrl]);
	}

	/** Navigates the the same level using full URL
	 * Similar to router.navigate(['../NEW_ROUTE']) or [routerLink]="'../NEW_ROUTE'",
	 * added because relative navigation doesn't work with ionic in the current
	 */
	public goBackAndNavigateForwardWithFullUrl(
		levelsUp: number = 1,
		forwardNavigationAfterNavigatingBack: string = null
	) {
		const newUrlBASE = removeSegmentsFromRoute(this.router.url, levelsUp);
		const newUrl = forwardNavigationAfterNavigatingBack
			? newUrlBASE + `/` + forwardNavigationAfterNavigatingBack
			: newUrlBASE;

		if (!isIonic()) {
			this.navCtrl.navigateForward([newUrl]);
		}
		this.router.navigate([newUrl]);
	}

	private subscribeToRoute() {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((route: NavigationEnd) => {
			this._route$.next(route.url);
		});
	}

	public shouldRouteHideSidenav(url: string): boolean {
		return CFG.ui.sidebarHidden.some((targetUrl) => getUrlWoParams(url).startsWith(targetUrl));
	}

	get isFullscreenPCT() {
		const isFullScreenEnabled = this.PCTOnlyMode;
		const url = this.PCTOnlyMode ? `/${PLAN_SELECTION_ONLY_PATH}` : `/${PLAN_SELECTION_PATH}`;

		return this.router.url === url && isFullScreenEnabled;
	}

	public shouldRouteHideBottomBar(url: string): boolean {
		return CFG.ui.bottomBarHidden.some((targetUrl) => getUrlWoParams(url).startsWith(targetUrl));
	}

	public shouldRouteHideTopBar(url: string): boolean {
		return CFG.ui.topBarHidden.some((targetUrl) => getUrlWoParams(url).startsWith(targetUrl));
	}

	public isMobileDeviceScreen(): Observable<boolean> {
		return fromEvent(window, 'resize').pipe(
			startWith(null),
			map(() => window.innerWidth <= CFG.ui.maxWidthForMobile)
		);
	}
}

import { T } from '@transifex/angular';

export class QuickAccessLocalize {
	@T('I have a coverage question')
	static chatItem: string;

	@T('I need a doctor or service')
	static careCostItem: string;

	@T('Find urgent care')
	static urgentCareItem: string;

	@T('Telehealth')
	static telehealthDialogTitle: string;

	@T('Immediate Care')
	static immediateCareTitle: string;
}

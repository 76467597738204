import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { CommonComponent } from '../../../../utils/components/common-component';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { DeductiblesData } from '../../../../services/stores/user-plan-data-store/helpers/deductibles-data';
import { Maybe } from '../../../../utils/types/maybe';
import { ConnectDeductiblesFormHandlerComponent } from '../../connect-deductibles-form-handler/connect-deductibles-form-handler/connect-deductibles-form-handler.component';
import { isNil } from '../../../../utils/is/is-nil';
import { TrackingService } from '../../../../services/tracking.service';
import { DeductiblesStoreService } from '../../../../services/stores/deductibles-store/deductibles-store.service';
import { DeductiblesApiService } from '../../../../services/api/deductibles-api/deductibles-api.service';
import { Deductible } from '../../../../services/api/domain/deductible';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { T } from '@transifex/angular';
import { filter, tap } from 'rxjs/operators';
import { PlanType } from 'src/app/models/plan-type.model';
import { MeUserStoreService } from 'src/app/services/stores/me-user-store/me-user-store.service';
import { UserCoverageTier } from 'src/app/services/api/domain/user-coverage-tier';
import { CoverageTierValues } from 'src/app/modules/home-page/teasers/deductible-teaser/deductible-teaser.localize';

const INDIVIDUAL_COVERAGE_TIER_INDEX = 0;
const FAMILY_COVERAGE_TIER_INDEX = 1;

@Component({
	selector: 'app-medical-spending',
	templateUrl: './medical-spending.component.html',
	styleUrls: ['./medical-spending.component.scss'],
})
export class MedicalSpendingComponent extends CommonComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('individualWrapperRef', { static: false }) individualWrapperRef: ElementRef;
	@ViewChild('individualRef', { static: false }) individualRef: ElementRef;
	@ViewChild('familyWrapperRef', { static: false }) familyWrapperRef: ElementRef;
	@ViewChild('familyRef', { static: false }) familyRef: ElementRef;

	public isIndividualOverflowing = false;
	public isFamilyOverflowing = false;

	@T('Disconnect your plan')
	disconnectTitle: string;

	@T('Are you sure that you want disconnect your plan?')
	disconnectMessage: string;

	@T('No')
	disconnectCancel: string;

	@T('Yes')
	disconnectConfirm: string;

	selectedTab$ = new BehaviorSubject<number>(INDIVIDUAL_COVERAGE_TIER_INDEX);

	disconnectedDeductiblesData$: Observable<DeductiblesData>;

	hasRibbonInsurancePartner$: Observable<boolean>;

	isConnected$: Observable<boolean>;

	connectedDeductiblesData$: Observable<Maybe<Deductible>>;

	isDeductibleSyncing: boolean = false;

	hasMedicalPlanByKayser$ = this.userPlanDataStoreService.hasMedicalPlanByKayser();

	userCoverageTier$: Observable<UserCoverageTier> = this.meUserStoreService.getUserCoverageTier();

	subscriber = new Subscription();

	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private deductiblesStoreService: DeductiblesStoreService,
		private deductiblesApiService: DeductiblesApiService,
		private trackingService: TrackingService,
		private healtheeDialogService: HealtheeDialogService,
		private meUserStoreService: MeUserStoreService
	) {
		super();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.checkOverflow();
		}, 200);
	}

	public checkOverflow() {
		if (this.individualRef && this.individualWrapperRef) {
			this.isIndividualOverflowing =
				this.individualRef.nativeElement.offsetWidth > this.individualWrapperRef.nativeElement.offsetWidth;
		}
		if (this.familyRef && this.familyWrapperRef) {
			this.isFamilyOverflowing =
				this.familyRef.nativeElement.offsetWidth > this.familyWrapperRef.nativeElement.offsetWidth;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.checkOverflow();
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.isConnected$ = this.deductiblesStoreService.isConnected();

		this.hasRibbonInsurancePartner$ = this.userPlanDataStoreService.hasRibbonInsurancePartner();

		this.disconnectedDeductiblesData$ = this.userPlanDataStoreService.medicalDeductibleIndividualData();

		this.connectedDeductiblesData$ = this.deductiblesStoreService.get();

		this.subscriber = this.userCoverageTier$
			.pipe(
				tap((userCoverageTiers: UserCoverageTier) => {
					const isIndividualCoverageTier = userCoverageTiers.medicalPlanTier !== CoverageTierValues.FAMILY;
					this.selectedTab$.next(
						isIndividualCoverageTier ? INDIVIDUAL_COVERAGE_TIER_INDEX : FAMILY_COVERAGE_TIER_INDEX
					);
				})
			)
			.subscribe();
	}

	focusOnMemberID(deductiblesFormHandler: Maybe<ConnectDeductiblesFormHandlerComponent>) {
		if (isNil(deductiblesFormHandler)) {
			return;
		}
		this.checkOverflow();
		deductiblesFormHandler.memberIDFormControl.nativeElement.focus();
	}

	disconnect() {
		this.healtheeDialogService
			.open({
				hasCloseButton: true,
				title: this.disconnectTitle,
				message: this.disconnectMessage,
				cancelText: this.disconnectCancel,
				confirmText: this.disconnectConfirm,
			})
			.pipe(
				filter((confirmed) => confirmed),
				switchMap(() => this.deductiblesApiService.disconnect(PlanType.medical))
			)
			.subscribe({
				next: () => this.track('Deductible Disconnect success'),
			});
	}

	public track(event, metaData = {}): void {
		this.trackingService.trackClientEvent(event, metaData);
	}

	public onDeductibleSyncStatusChanged($event: any): void {
		this.isDeductibleSyncing = $event;
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.subscriber.unsubscribe();
	}
}

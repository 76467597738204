import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Contract } from '../../../models/contract.model';
import { PlanType } from '../../../models/plan-type.model';
import { Maybe } from '../../../utils/types/maybe';
import { isNil } from '../../../utils/is/is-nil';

@Component({
	selector: 'app-digital-card',
	templateUrl: './digital-card.component.html',
	styleUrls: ['./digital-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DigitalCardComponent implements OnInit {
	public header: string;
	public backgroundColors = {
		medical: '#0065CC',
		dental: '#FFC434',
		vision: '#FE5201',
	};
	public backgroundColor: string;
	public logo: string;

	@Input()
	contract: Maybe<Contract>;

	@Input()
	planType: Maybe<PlanType>;

	constructor() {}

	ngOnInit(): void {
		const planType = isNil(this.contract) ? this.planType : this.contract.planType;

		this.header = isNil(planType) ? 'Plan' : planType.charAt(0).toUpperCase() + planType.slice(1) + ' plan';

		const iconUrl = this.contract?.insuranceCompany?.iconURL;
		this.logo = iconUrl ? `https://assets.healthee.co/insurance_company_icons/${iconUrl}` : null;

		if (!this.contract?.inactive && !isNil(planType)) this.backgroundColor = this.backgroundColors[planType];
	}
}

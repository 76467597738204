import { Injectable } from '@angular/core';

import { Observable, combineLatest, map, take } from 'rxjs';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { PlanSelectionStoreService } from '../services/stores/plan-selection-store/plan-selection-store.service';
import CFG from 'src/app/config/app-config.json';
import { RxjsUtils } from '../utils/rxjs';
import { UnleashService } from '../services/unleash.service';
import { PCT_2_FF, PCT_2_GRADUAL_FF, PCT_2_PARTIAL_FF } from './pct-only.guard';
import { PCT_2_PATH, PCT_2_TRINET_PATH } from '../modules/main-layout/main-layout.constants';

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionGuard implements CanActivate {
	private isPCT2Enabled$ = this.unleashService.isEnabled$(PCT_2_FF);
	public isPCT2PartialEnabled$ = this.unleashService.isEnabled$(PCT_2_PARTIAL_FF);
	public isPCT2GradualEnabled$ = this.unleashService.isEnabled$(PCT_2_GRADUAL_FF);
	public isPlanSelectionOpen$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();
	public isTrinet$ = this.planSelectionStoreService.get().pipe(
		RxjsUtils.isNotNil(),
		take(1),
		map((data) => data.companyData.isTriNet)
	);

	constructor(
		private router: Router,
		private planSelectionStoreService: PlanSelectionStoreService,
		private unleashService: UnleashService
	) {}

	public canActivate(): Observable<boolean | UrlTree> {
		return combineLatest([
			this.isPCT2Enabled$,
			this.isPCT2PartialEnabled$,
			this.isPCT2GradualEnabled$,
			this.isPlanSelectionOpen$,
			this.isTrinet$,
		]).pipe(
			map(([isPCT2Enabled, isPCT2PartialEnabled, isPCT2GradualEnabled, isPlanSelectionOpen, isTrinet]) => {
				const redirectPath = isTrinet ? PCT_2_TRINET_PATH : PCT_2_PATH;
				if (isPCT2Enabled || (isPCT2PartialEnabled && isPCT2GradualEnabled)) {
					window.location.href = redirectPath;
				}
				return isPlanSelectionOpen || this.router.createUrlTree([CFG.defaultAppRoute]);
			}),
			take(1)
		);
	}
}

<div class="copay">
	<span class="icon-container">
		<img src='/assets/icons/icon-wallet-coverage.svg' alt="wallet-icon"/>
	</span>
	<span class="content-container" *ngIf="getCopayText() as copayInfo">
		<div class="headline-container" *ngIf="copayInfo.headline">
			{{ copayInfo.headline }}
		</div>
		<div class="text-container">
			<span class="green">{{ copayInfo.textGreen }}</span><span class="dark-blue">{{ copayInfo.textDBlue }}</span>
		</div>
	</span>
</div>

<div class="mr" *ngIf="getMRText() as mrInfo">
	<span class="icon-container">
		<img src='/assets/icons/icon-wallet-coverage.svg' alt="wallet-icon"/>
	</span>
	<span class="content-container">
		<div class="headline-container">
			{{ mrInfo.headline }}
		</div>
		<div class="text-container">
			<span class="green">{{ mrInfo.textGreen }}</span><span class="dark-blue">{{ mrInfo.textDBlue }}</span>
		</div>
	</span>
</div>
<div class="deductible-container">
	<p>{{ (subjectToDeductible ? 'subject to deductible' : 'not subject to deductible') | translate }}</p>
</div>


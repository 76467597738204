<div class="service-panel" *ngIf="!isAdditionalService; else displayOldLayout">
	<ng-container *ngIf="activeBenefit; else displayLoadingSpinner">
		<app-service-panel-header
			[shrinked]="isHeaderShrinked$ | async"
			[bgImage]="(service$ | async)?.iconPath | serviceImage"
			[title]="(service$ | async)?.abbreviate"
			[serviceCoverage]="serviceCaverage$ | async"
			(closeClick)="closeClick.emit()"
		></app-service-panel-header>

		<div
			class="service-content without-top-padding"
			[class.with-padding]="isHeaderShrinked$ | async"
			(scroll)="contentScrollPos$.next($any($event?.srcElement)?.scrollTop)"
		>
			<div
				class="service-content-tabs"
				[ngClass]="{ 'margin-bottom': (benefit$ | async)?.relatedServices.length === 0 }"
			>
				<ng-container
					*ngIf="
						isEnabledCoverageInfoRestructure &&
							(isNewBenefitsFromDDBEnabled$ | async) &&
							activeBenefit === BenefitType.NEW_FROM_DDB &&
							newBenefitFromDDB$ | async as newBenefitFromDDB;
						else displayNewBenefit
					"
				>
					<nav mat-tab-nav-bar disableRipple [tabPanel]="tabPanel">
						<a
							mat-tab-link
							*ngFor="let network of newBenefitFromDDB?.networks; let i = index"
							(click)="setActiveTab(i)"
							[active]="activeNetworkTabIndex === i"
						>
							<div
								class="service-content-tab"
								[ngClass]="
									network.title.toLowerCase() === OON_MN_NAME ||
									network.title.toLowerCase() === OON_SIMPLE_NAME
										? 'red'
										: 'green'
								"
							>
								<span></span>
								{{ network.title | translate }}
							</div>
						</a>
					</nav>
					<mat-tab-nav-panel #tabPanel>
						<div class="service-content-copay">
							<app-service-panel-copay
								*ngIf="(isBenefitsUiImprovementsPhase1Enabled$ | async) === false"
								[copay]="newBenefitFromDDB?.networks[activeNetworkTabIndex].copay"
								[mr]="newBenefitFromDDB?.networks[activeNetworkTabIndex].memberResponsibility"
								[subjectToDeductible]="
									newBenefitFromDDB?.networks[activeNetworkTabIndex].subjectToDeductible
								"
								[dependsOnLocation]="
									newBenefitFromDDB?.networks[activeNetworkTabIndex].dependsOnLocation
								"
							></app-service-panel-copay>

							<app-service-panel-information
								*ngIf="(isBenefitsUiImprovementsPhase1Enabled$ | async) === true"
								[networkTitle]="newBenefitFromDDB?.networks[activeNetworkTabIndex].title"
								[copay]="newBenefitFromDDB?.networks[activeNetworkTabIndex].copay"
								[serviceId]="newBenefitFromDDB?.service"
								[mr]="newBenefitFromDDB?.networks[activeNetworkTabIndex].memberResponsibility"
								[subjectToDeductible]="
									newBenefitFromDDB?.networks[activeNetworkTabIndex].subjectToDeductible
								"
								[dependsOnLocation]="
									newBenefitFromDDB?.networks[activeNetworkTabIndex].dependsOnLocation
								"
							></app-service-panel-information>
						</div>
						<div>
							<app-service-panel-accordion
								[title]="'Appointment Checklist'"
								[description]="'Here are a few items you might need prior to your appointment'"
								[count]="
									getAppointmentChecklistCount(newBenefitFromDDB?.networks[activeNetworkTabIndex])
								"
								[isExpanded]="true"
							>
								<app-service-panel-card
									[iconURL]="'/assets/icons/category/61d2998942f33e4082a17019.svg'"
									[title]="'Referral'"
									[description]="
										newBenefitFromDDB?.networks[activeNetworkTabIndex].referralRequired
											? 'You\'ll need a referral from your Primary Care Physician for this service'
											: ''
									"
									[labelText]="
										newBenefitFromDDB?.networks[activeNetworkTabIndex].referralRequired
											? 'Required'
											: 'Not Required'
									"
									[badgeSuccess]="
										!newBenefitFromDDB?.networks[activeNetworkTabIndex].referralRequired
									"
								></app-service-panel-card>
								<app-service-panel-card
									[iconURL]="'/assets/icons/hipaa-icon.svg'"
									[title]="'Pre-Authorization '"
									[description]="
										newBenefitFromDDB?.networks[activeNetworkTabIndex].preAuth
											? 'You\'ll need a pre-authorization for this service'
											: ''
									"
									[labelText]="
										newBenefitFromDDB?.networks[activeNetworkTabIndex].preAuth
											? 'Required'
											: 'Not Required'
									"
									[badgeSuccess]="!newBenefitFromDDB?.networks[activeNetworkTabIndex].preAuth"
								></app-service-panel-card>
							</app-service-panel-accordion>
							<app-service-panel-accordion
								*ngIf="newBenefitFromDDB | isShowImportantToKnow : activeNetworkTabIndex"
								[title]="'Important to know'"
								[description]="
									'Here are a few more items your policy mentions about coverage related to this topic'
								"
								[count]="
									getImportantToKnowCount(
										newBenefitFromDDB?.networks[activeNetworkTabIndex],
										newBenefitFromDDB?.gender
									)
								"
								[isExpanded]="false"
							>
								<app-service-panel-card
									*ngIf="
										newBenefitFromDDB?.networks[activeNetworkTabIndex]
											.reimbursement as reimbursement
									"
									[title]="'Reimbursements'"
									[description]="'Your plan will reimburse you up to'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{ reimbursement | displayTypeAndAmount }}"
										[colorTheme]="LabelColorTheme.darkTextGreenBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="newBenefitFromDDB?.gender"
									[title]="'Gender Limitation'"
									[description]="'Your policy only covers this service for individuals'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{ newBenefitFromDDB?.gender }}"
										[colorTheme]="LabelColorTheme.darkTextRedBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="newBenefitFromDDB?.networks[activeNetworkTabIndex].ageRestriction"
									[title]="'Age Limitation'"
									[description]="'Your policy only covers this service for individuals'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{
											newBenefitFromDDB?.networks[activeNetworkTabIndex].ageRestriction.min
										}}
												{{
											newBenefitFromDDB?.networks[activeNetworkTabIndex].ageRestriction.max
												? ' - ' +
												  newBenefitFromDDB?.networks[activeNetworkTabIndex].ageRestriction.max
												: '+'
										}}"
										[colorTheme]="LabelColorTheme.darkTextRedBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="newBenefitFromDDB?.networks[activeNetworkTabIndex].countLimitations"
									[title]="'Count Limitation'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{
											getCountLimitationsText(
												newBenefitFromDDB?.networks[activeNetworkTabIndex].countLimitations
											)
										}}"
										[colorTheme]="LabelColorTheme.darkTextRedBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="
										newBenefitFromDDB?.networks[activeNetworkTabIndex].preExistConditions?.length
									"
									[title]="'Pre-existing Conditions'"
									[description]="'Only if you have medical precondition'"
								>
									<div class="separator"></div>
									<ng-container
										*ngFor="
											let condition of newBenefitFromDDB?.networks[activeNetworkTabIndex]
												.preExistConditions
										"
									>
										<ripple-label
											*ngFor="let innerCondition of condition.condition"
											text="{{ (getMedicalConditionById(innerCondition) | async)?.name }}"
											[colorTheme]="LabelColorTheme.darkTextRedBg"
										></ripple-label>
									</ng-container>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="newBenefitFromDDB?.networks[activeNetworkTabIndex].notes?.length"
									[title]="'Notes'"
								>
									<div class="separator"></div>
									<div
										class="note"
										*ngFor="let note of newBenefitFromDDB?.networks[activeNetworkTabIndex].notes"
									>
										{{ note | translate }}
									</div>
								</app-service-panel-card>
							</app-service-panel-accordion>
						</div>
					</mat-tab-nav-panel>
				</ng-container>
				<ng-template #displayNewBenefit>
					<ng-container
						*ngIf="
							isEnabledCoverageInfoRestructure && activeBenefit === BenefitType.NEW;
							else displayOldBenefit
						"
					>
						<nav mat-tab-nav-bar disableRipple [tabPanel]="tabPanel">
							<a
								mat-tab-link
								*ngFor="let network of (newBenefit$ | async)?.networks; let i = index"
								(click)="setActiveTab(i)"
								[active]="activeNetworkTabIndex === i"
							>
								<div
									class="service-content-tab"
									[ngClass]="
										network.title.toLowerCase() === OON_MN_NAME ||
										network.title.toLowerCase() === OON_SIMPLE_NAME
											? 'red'
											: 'green'
									"
								>
									<span></span>
									{{ network.title | translate }}
								</div>
							</a>
						</nav>
						<mat-tab-nav-panel #tabPanel>
							<div class="service-content-copay">
								<app-service-panel-copay
									*ngIf="(isBenefitsUiImprovementsPhase1Enabled$ | async) === false"
									[copay]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].copay"
									[mr]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].memberResponsibility"
									[subjectToDeductible]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].subjectToDeductible
									"
									[dependsOnLocation]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].dependsOnLocation
									"
								></app-service-panel-copay>

								<app-service-panel-information
									*ngIf="(isBenefitsUiImprovementsPhase1Enabled$ | async) === true"
									[networkTitle]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].title"
									[copay]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].copay"
									[serviceId]="(newBenefit$ | async)?.service"
									[mr]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].memberResponsibility"
									[subjectToDeductible]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].subjectToDeductible
									"
									[dependsOnLocation]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].dependsOnLocation
									"
								></app-service-panel-information>
							</div>
							<div>
								<app-service-panel-accordion
									[title]="'Appointment Checklist'"
									[description]="'Here are a few items you might need prior to your appointment'"
									[count]="
										getAppointmentChecklistCount(
											(newBenefit$ | async)?.networks[activeNetworkTabIndex]
										)
									"
									[isExpanded]="true"
								>
									<app-service-panel-card
										[iconURL]="'/assets/icons/category/61d2998942f33e4082a17019.svg'"
										[title]="'Referral'"
										[description]="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
												? 'You\'ll need a referral from your Primary Care Physician for this service'
												: ''
										"
										[labelText]="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
												? 'Required'
												: 'Not Required'
										"
										[badgeSuccess]="
											!(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
										"
									></app-service-panel-card>
									<app-service-panel-card
										[iconURL]="'/assets/icons/hipaa-icon.svg'"
										[title]="'Pre-Authorization '"
										[description]="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].preAuth
												? 'You\'ll need a pre-authorization for this service'
												: ''
										"
										[labelText]="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].preAuth
												? 'Required'
												: 'Not Required'
										"
										[badgeSuccess]="!(newBenefit$ | async)?.networks[activeNetworkTabIndex].preAuth"
									></app-service-panel-card>
								</app-service-panel-accordion>
								<app-service-panel-accordion
									*ngIf="newBenefit$ | async | isShowImportantToKnow : activeNetworkTabIndex"
									[title]="'Important to know'"
									[description]="
										'Here are a few more items your policy mentions about coverage related to this topic'
									"
									[count]="
										getImportantToKnowCount(
											(newBenefit$ | async)?.networks[activeNetworkTabIndex],
											(newBenefit$ | async)?.gender
										)
									"
									[isExpanded]="false"
								>
									<app-service-panel-card
										*ngIf="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex]
												.reimbursement as reimbursement
										"
										[title]="'Reimbursements'"
										[description]="'Your plan will reimburse you up to'"
									>
										<div class="separator"></div>
										<ripple-label
											text="{{ reimbursement | displayTypeAndAmount }}"
											[colorTheme]="LabelColorTheme.darkTextGreenBg"
										></ripple-label>
									</app-service-panel-card>
									<app-service-panel-card
										*ngIf="(newBenefit$ | async)?.gender"
										[title]="'Gender Limitation'"
										[description]="'Your policy only covers this service for individuals'"
									>
										<div class="separator"></div>
										<ripple-label
											text="{{ (newBenefit$ | async)?.gender }}"
											[colorTheme]="LabelColorTheme.darkTextRedBg"
										></ripple-label>
									</app-service-panel-card>
									<app-service-panel-card
										*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction"
										[title]="'Age Limitation'"
										[description]="'Your policy only covers this service for individuals'"
									>
										<div class="separator"></div>
										<ripple-label
											text="{{
												(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction
													.min
											}}
														{{
												(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction
													.max
													? ' - ' +
													  (newBenefit$ | async)?.networks[activeNetworkTabIndex]
															.ageRestriction.max
													: '+'
											}}"
											[colorTheme]="LabelColorTheme.darkTextRedBg"
										></ripple-label>
									</app-service-panel-card>
									<app-service-panel-card
										*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].countLimitations"
										[title]="'Count Limitation'"
									>
										<div class="separator"></div>
										<ripple-label
											text="{{
												getCountLimitationsText(
													(newBenefit$ | async)?.networks[activeNetworkTabIndex]
														.countLimitations
												)
											}}"
											[colorTheme]="LabelColorTheme.darkTextRedBg"
										></ripple-label>
									</app-service-panel-card>
									<app-service-panel-card
										*ngIf="
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].preExistConditions
												?.length
										"
										[title]="'Pre-existing Conditions'"
										[description]="'Only if you have medical precondition'"
									>
										<div class="separator"></div>
										<ng-container
											*ngFor="
												let condition of (newBenefit$ | async)?.networks[activeNetworkTabIndex]
													.preExistConditions
											"
										>
											<ripple-label
												*ngFor="let innerCondition of condition.condition"
												text="{{ (getMedicalConditionById(innerCondition) | async)?.name }}"
												[colorTheme]="LabelColorTheme.darkTextRedBg"
											></ripple-label>
										</ng-container>
									</app-service-panel-card>
									<app-service-panel-card
										*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].notes?.length"
										[title]="'Notes'"
									>
										<div class="separator"></div>
										<div
											class="note"
											*ngFor="
												let note of (newBenefit$ | async)?.networks[activeNetworkTabIndex].notes
											"
										>
											{{ note | translate }}
										</div>
									</app-service-panel-card>
								</app-service-panel-accordion>
							</div>
						</mat-tab-nav-panel>
					</ng-container>
				</ng-template>
				<ng-template #displayOldBenefit>
					<ng-container *ngIf="!isAdditionalService && benefit$ | async as benefit">
						<div
							*ngIf="activeBenefit === BenefitType.NOT_CATEGORIZED"
							class="intercom-access"
							[ngClass]="{
								'border-bottom':
									(benefit$ | async)?.relatedServices.length === 0 ||
									(benefit$ | async)?.desc ||
									(benefit$ | async)?.notes?.length > 0
							}"
						>
							<div class="description">
								<div class="icon">
									<ripple-icon name="benefitSpecialist"></ripple-icon>
								</div>
								<div class="text">
									Chat with our benefits specialist to learn more about this benefit
								</div>
							</div>
							<button class="open-intercom" type="open-intercom" (click)="openIntercom()">
								Chat Now
							</button>
						</div>
						<!-- header-tabs -->
						<nav
							*ngIf="
								benefit.networkStructure === NetworkStructure.SINGLE &&
								activeBenefit !== BenefitType.NOT_CATEGORIZED
							"
							mat-tab-nav-bar
							disableRipple
							[tabPanel]="tabPanel"
						>
							<a mat-tab-link (click)="setActiveTab(0)" [active]="activeNetworkTabIndex === 0">
								<div class="service-content-tab" [class.green]="activeNetworkTabIndex === 0">
									<span></span>
									{{ 'In-Network' | translate }}
								</div>
							</a>
							<a mat-tab-link (click)="setActiveTab(1)" [active]="activeNetworkTabIndex === 1">
								<div class="service-content-tab" [class.red]="activeNetworkTabIndex === 1">
									<span></span>
									{{ 'Out-of-Network' | translate }}
								</div>
							</a>
						</nav>
						<nav
							*ngIf="
								(benefit.networkStructure === NetworkStructure.MN ||
									benefit.networkStructure === NetworkStructure.MTN) &&
								activeBenefit !== BenefitType.NOT_CATEGORIZED
							"
							mat-tab-nav-bar
							disableRipple
							[tabPanel]="tabPanel"
						>
							<a
								*ngFor="let network of benefit.additionalNetworks; let i = index"
								[active]="activeNetworkTabIndex === i"
								mat-tab-link
								(click)="setActiveTab(i)"
							>
								<div class="service-content-tab" [class.green]="activeNetworkTabIndex === i">
									<span></span>
									{{ network.name }}
								</div>
							</a>
						</nav>
						<!-- body-tabs -->
						<mat-tab-nav-panel #tabPanel>
							<br />
							<div *ngIf="activeBenefit !== BenefitType.NOT_CATEGORIZED">
								<div
									*ngIf="benefit.networkStructure === NetworkStructure.SINGLE"
									class="single-network"
								>
									<div class="service-content-coverage green" *ngIf="activeNetworkTabIndex === 0">
										<div class="service-content-coverage-header">
											<span></span>
											<div>{{ 'In-Network' | translate }}</div>
										</div>
										<div class="service-content-coverage-body">
											<div
												*ngIf="!benefit.subjectToDeductibleIn"
												class="service-content-coverage-body-title"
											>
												{{ 'Not subject to deductible' | translate }}
											</div>
											<div
												*ngIf="benefit.subjectToDeductibleIn"
												class="service-content-coverage-body-title"
											>
												{{ 'Subject to deductible' | translate }}
											</div>
											<div class="service-content-coverage-body-subtitle">
												{{ benefit.inNetwork }}
											</div>
										</div>
									</div>
									<div class="service-content-coverage red" *ngIf="activeNetworkTabIndex === 1">
										<div class="service-content-coverage-header">
											<span></span>
											<div>{{ 'Out-of-Network' | translate }}</div>
										</div>
										<div class="service-content-coverage-body">
											<div
												*ngIf="!benefit.subjectToDeductibleOut"
												class="service-content-coverage-body-title"
											>
												{{ 'Not subject to deductible' | translate }}
											</div>
											<div
												*ngIf="benefit.subjectToDeductibleOut"
												class="service-content-coverage-body-title"
											>
												{{ 'Subject to deductible' | translate }}
											</div>
											<div class="service-content-coverage-body-subtitle">
												{{ benefit.outNetwork }}
											</div>
										</div>
									</div>
								</div>
								<div
									*ngIf="
										benefit.networkStructure === NetworkStructure.MN ||
										benefit.networkStructure === NetworkStructure.MTN
									"
									class="multi-network"
								>
									<div *ngFor="let network of benefit.additionalNetworks; let i = index">
										<div
											*ngIf="activeNetworkTabIndex === i"
											class="service-content-coverage"
											[ngClass]="network.name.toLowerCase() === OON_MN_NAME ? 'red' : 'green'"
										>
											<div class="service-content-coverage-header">
												<span></span>
												<div *ngIf="network.name.toLowerCase() !== OON_MN_NAME">
													{{ 'In-Network' | translate }}
												</div>
												<div *ngIf="network.name.toLowerCase() === OON_MN_NAME">
													{{ 'Out-of-Network' | translate }}
												</div>
											</div>
											<div class="service-content-coverage-body">
												<div
													*ngIf="!network.subjectToDeductible"
													class="service-content-coverage-body-title"
												>
													{{ 'Not subject to deductible' | translate }}
												</div>
												<div
													*ngIf="network.subjectToDeductible"
													class="service-content-coverage-body-title"
												>
													{{ 'Subject to deductible' | translate }}
												</div>
												<div class="service-content-coverage-body-subtitle">
													{{ network.value }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="service-content-coverage green rbp-indemnity"
									*ngIf="
										benefit.networkStructure === NetworkStructure.RBP ||
										benefit.networkStructure === NetworkStructure.INDEMNITY
									"
								>
									<div class="service-content-coverage-header">
										<div *ngIf="!benefit.subjectToDeductibleIn">
											{{ 'Not subject to deductible' | translate }}
										</div>
										<div *ngIf="benefit.subjectToDeductibleIn">
											{{ 'Subject to deductible' | translate }}
										</div>
										<div class="service-content-coverage-body-subtitle">
											{{ benefit.inNetwork }}
										</div>
									</div>
									<div class="service-content-coverage-body">
										<div class="service-content-coverage-body-subtitle">
											<div *ngIf="benefit.networkStructure === NetworkStructure.RBP">
												<div>
													{{
														'Note: Not all healthcare providers may participate in an RBP arrangement, which could lead to balance-billing.'
															| translate
													}}
												</div>
											</div>
											<div *ngIf="benefit.networkStructure === NetworkStructure.INDEMNITY">
												<div>
													{{
														'Note: The plan pays for costs up to the UCR price and will refund you based on this amount once you file a claim.'
															| translate
													}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="(benefit$ | async)?.desc" class="service-content-info">
								<div class="service-content-info-title">
									{{ 'Important to know' | translate }}
								</div>
								<div class="service-content-info-body" [innerHTML]="(benefit$ | async)?.desc"></div>
							</div>
							<div class="service-content-info" *ngIf="(benefit$ | async)?.notes?.length > 0">
								<div class="service-content-info-title for-note">
									{{ 'Notes' | translate }}
								</div>
								<div
									class="service-content-info-body for-note"
									*ngFor="let note of (benefit$ | async)?.notes"
									[innerHTML]="note"
								></div>
								<div
									(click)="trackBrochureEvent()"
									class="pdf-link"
									*ngIf="(benefit$ | async)?.brochureFileKey"
								>
									<UT
										str="Full details and claims info <a href={link} target='_blank'>here</a>"
										[vars]="{
											link: ((benefit$ | async)?.brochureFileKey | additionalBenefitBrochure)
										}"
									></UT>
								</div>
							</div>
						</mat-tab-nav-panel>
					</ng-container>
				</ng-template>
			</div>

			<app-service-panel-related-services
				*ngIf="userBenefitsMap$ | async"
				[services]="(benefit$ | async)?.relatedServices"
				[benefitId]="(benefit$ | async)?._id"
				[userBenefitsMap]="userBenefitsMap$ | async"
				[serviceName]="(service$ | async)?.abbreviate"
				[relatedBenefitClick]="relatedBenefitClick"
			></app-service-panel-related-services>
		</div>

		<app-service-panel-footer
			[displayFindProviders]="(showFindProvidersButton$ | async) && !hideFindProvidersButton"
			(feedbackClick)="onFeedbackClick(0)"
			(findProviderClick)="onFindProvidersClick()"
		></app-service-panel-footer>
	</ng-container>

	<ng-template #displayLoadingSpinner>
		<div class="service-panel-loading">
			<app-loader size="medium"></app-loader>
		</div>
	</ng-template>
</div>

<ng-template #displayOldLayout>
	<div class="service-panel">
		<div class="service-header" [class.service-header-shrink]="isHeaderShrinked$ | async">
			<div class="service-header-image">
				<div [ngStyle]="{ 'background-image': 'url(/assets/images/default-service-image-large.svg)' }"></div>
				<div
					*ngIf="!isAdditionalService; else additionalService"
					[ngStyle]="{
						'background-image': 'url(' + ((service$ | async)?.iconPath | serviceImage) + ')'
					}"
				></div>
				<ng-template #additionalService>
					<div
						[ngStyle]="{
							'background-image': 'url(' + ((service$ | async)?.iconURL | additionalBenefitImage) + ')'
						}"
					></div>
				</ng-template>
			</div>
			<div class="service-header-content">
				<div class="service-header-close-and-label">
					<button
						(click)="closeClick.emit()"
						class="service-header-content-back-button close-button"
						mat-icon-button
					>
						<mat-icon>close</mat-icon>
					</button>

					<button
						(click)="closeClick.emit()"
						class="service-header-content-back-button arrow-back"
						mat-icon-button
					>
						<mat-icon>arrow_back</mat-icon>
					</button>
				</div>

				<div class="service-header-content-subtitle">Primary Care | Preventive screening</div>
				<div class="service-header-content-title">
					{{ (service$ | async)?.abbreviate || (service$ | async)?.slug || (service$ | async)?.name }}
				</div>
			</div>
		</div>
		<div
			class="service-content"
			[class.with-padding]="isHeaderShrinked$ | async"
			(scroll)="contentScrollPos$.next($any($event?.srcElement)?.scrollTop)"
		>
			<ng-container *ngIf="!isAdditionalService">
				<br />
				<div class="service-content-title">
					<div class="service-content-title-text">{{ 'Coverage' | translate }}</div>

					<span
						class="service-content-title-coverage covered"
						*ngIf="(userBenefitsMap$ | async)?.get(serviceId)?.covered === 'covered'"
					>
						{{ 'Covered' | translate }}
					</span>
					<span
						class="service-content-title-coverage not-covered"
						*ngIf="(userBenefitsMap$ | async)?.get(serviceId)?.covered !== 'covered'"
					>
						{{ 'Not Covered' | translate }}
					</span>
				</div>
				<div class="service-content-coverage green">
					<div class="service-content-coverage-header">
						<span></span>
						<div>{{ 'In-Network' | translate }}</div>
					</div>
					<div class="service-content-coverage-body">
						<div
							*ngIf="!(benefit$ | async)?.subjectToDeductibleIn"
							class="service-content-coverage-body-title"
						>
							{{ 'Not subject to deductible' | translate }}
						</div>
						<div
							*ngIf="(benefit$ | async)?.subjectToDeductibleIn"
							class="service-content-coverage-body-title"
						>
							{{ 'Subject to deductible' | translate }}
						</div>
						<div class="service-content-coverage-body-subtitle">
							{{ (benefit$ | async)?.inNetwork }}
						</div>
					</div>
				</div>

				<div class="service-content-coverage red">
					<div class="service-content-coverage-header">
						<span></span>
						<div>{{ 'Out-of-Network' | translate }}</div>
					</div>
					<div class="service-content-coverage-body">
						<div
							*ngIf="!(benefit$ | async)?.subjectToDeductibleOut"
							class="service-content-coverage-body-title"
						>
							{{ 'Not subject to deductible' | translate }}
						</div>
						<div
							*ngIf="(benefit$ | async)?.subjectToDeductibleOut"
							class="service-content-coverage-body-title"
						>
							{{ 'Subject to deductible' | translate }}
						</div>
						<div class="service-content-coverage-body-subtitle">
							{{ (benefit$ | async)?.outNetwork }}
						</div>
					</div>
				</div>
			</ng-container>
			<div *ngIf="(benefit$ | async)?.desc" class="service-content-info">
				<div class="service-content-info-title">
					{{ 'Important to know' | translate }}
				</div>
				<div class="service-content-info-body" [innerHTML]="(benefit$ | async)?.desc"></div>
			</div>
			<div class="service-content-info">
				<ng-container *ngIf="(benefit$ | async)?.notes?.length > 0">
					<div class="service-content-info-title for-note">
						{{ 'Notes' | translate }}
					</div>
					<div
						class="service-content-info-body for-note"
						*ngFor="let note of (benefit$ | async)?.notes"
						[innerHTML]="note"
					></div>
					<div (click)="trackBrochureEvent()" class="pdf-link" *ngIf="(benefit$ | async)?.brochureFileKey">
						<UT
							str="Full details and claims info <a href={link} target='_blank'>here</a>"
							[vars]="{ link: ((benefit$ | async)?.brochureFileKey | additionalBenefitBrochure) }"
						></UT>
					</div>
				</ng-container>
			</div>

			<div
				class="find-providers-button-wrapper"
				*ngIf="(showFindProvidersButton$ | async) && !hideFindProvidersButton"
			>
				<button (click)="onFindProvidersClick()" class="find-providers-button">
					{{ 'Find Providers' | translate }}
					<mat-icon>arrow_right_alt</mat-icon>
				</button>
			</div>

			<div *ngIf="(benefit$ | async)?.relatedServices?.length > 0" class="service-content-benefits">
				<div class="service-content-benefits-title">{{ 'Related Benefits' | translate }}</div>

				<div class="service-content-benefits-wrapper">
					<div
						class="related-benefit"
						(click)="relatedBenefitClick.emit(service._id)"
						[appClientClickEventTracker]="{
							event: 'Coverage Info Related Benefits click',
							metaData: {
								'service name': (service$ | async)?.abbreviate,
								relatedServiceName: service.abbreviate
							}
						}"
						*ngFor="let service of (benefit$ | async)?.relatedServices; let i = index"
					>
						<img class="related-benefit-image" [src]="service.iconPath | serviceImage : i" />
						<div class="related-benefit-content">
							<span class="related-benefit-title">{{ service.abbreviate }}</span>
							<span
								class="coverage-badge covered"
								*ngIf="(userBenefitsMap$ | async)?.get(service._id)?.covered === 'covered'"
								>{{ 'Covered' | translate }}</span
							>
							<span
								class="coverage-badge not-covered"
								*ngIf="(userBenefitsMap$ | async)?.get(service._id)?.covered !== 'covered'"
								>{{ 'Not Covered' | translate }}</span
							>
						</div>
					</div>
				</div>
			</div>
			<div class="feedback-wrapper">
				<app-feedback-block
					[feature]="'benefit panel'"
					[data]="{ 'benefit ID': (benefit$ | async)?._id }"
				></app-feedback-block>
			</div>
		</div>
	</div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { T } from '@transifex/angular';
import { Capacitor } from '@capacitor/core';
import { Intercom as IntercomMobile } from '@capacitor-community/intercom';

import CFG from '../config/app-config.json';

declare global {
	interface Window {
		Intercom: any;
	}
}

export interface IntercomConversationResponse {
	conversationId: string;
}

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	// DO NOT change text indentation in the T decorator below, or it will break
	// the message the user sees on intercom messenger
	@T(`Hi!
Thanks for reaching out to our support team, let us look into this for you.
Hang tight, we will reach out to you soon via text message once we have an answer.`)
	public readonly zoeWelcomeMessageOnIntercom: string;

	public isEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _intercomHigher$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	get intercomHigher$() {
		return this._intercomHigher$.asObservable();
	}

	constructor(private http: HttpClient) {}

	public onShow(): void {
		this.isEnabled$.next(true);
	}

	public onHide(): void {
		this.isEnabled$.next(false);
	}

	public moveIntercomFromPositionPX(state: number): void {
		this._intercomHigher$.next(state);
	}
	public resetIntercomPosition(): void {
		this._intercomHigher$.next(0);
	}

	public initiateIntercomConversation(
		userMessage: string,
		zoeResponse: string, // Zoe response is sent by the client, to support multilanguage
		chatHistoryWithZoe: string = null
	) {
		const url = CFG.apiEndpoints.startIntercomConversation;
		return this.http.post<IntercomConversationResponse>(url, { userMessage, zoeResponse, chatHistoryWithZoe });
	}

	public sendIntercomEvent(eventName: string, data: any) {
		const url = CFG.apiEndpoints.sendIntercomEvent;
		return this.http.post(url, { eventName, data });
	}

	public updateIntercomConversation(
		conversationId: string,
		userMessage: string,
		zoeResponse: string, // Zoe response is sent by the client, to support multilanguage
		chatHistoryWithZoe: string = null
	) {
		const url = CFG.apiEndpoints.updateIntercomConversation;
		return this.http.post<IntercomConversationResponse>(url, {
			conversationId,
			userMessage,
			zoeResponse,
			chatHistoryWithZoe,
		});
	}

	openMessenger() {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayMessenger();
			return;
		}
		if (window.Intercom) {
			window.Intercom('show');
		}
	}

	openConversation(conversationId) {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayMessenger();
			return;
		}
		if (window.Intercom) {
			window.Intercom('showConversation', conversationId);
		}
	}

	openArticle(articleId: string): void {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayArticle({ articleId: articleId });
			return;
		}
		if (window.Intercom) {
			window['Intercom']('showArticle', articleId);
		}
	}
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from '../../../../models/menu-item.model';
import { QuickAccessLocalize } from './quick-access.localize';
import { LanguageService } from '../../../../services/language.service';
import { IntercomService } from '../../../../services/intercom.service';
type MenuItemWithSelected = MenuItem & { isActive: boolean; selected?: boolean; action?: () => void };

@Component({
	selector: 'app-quick-access',
	templateUrl: './quick-access.component.html',
	styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent {
	public quickAccessItems: MenuItemWithSelected[] = [
		{
			label: QuickAccessLocalize.chatItem,
			icon: 'question_mark.svg',
			analyticEvent: 'Home Page Coverage Info Click',
			action: () => {
				// TODO: Should change the string comparisson logic below to explicit flags on the language object
				if (this.languageService.currentLanguage === 'en' || this.languageService.currentLanguage === 'jhs') {
					this.router.navigate(['/coverage-info'], { fragment: 'Quick Access', preserveFragment: false });
					return;
				}
				this.intercomService.openMessenger();
			},
			isActive: true,
		},
		{
			label: QuickAccessLocalize.careCostItem,
			icon: 'stethoscope.svg',
			link: '/care-and-costs',
			isActive: true,
		},
		{
			label: QuickAccessLocalize.urgentCareItem,
			icon: 'urgent_care.svg',
			additionalIcon: 'coming_soon.svg',
			link: '/home',
			analyticEvent: 'Home Page Urgent Care',
			isActive: false,
		},
		{
			label: QuickAccessLocalize.immediateCareTitle,
			icon: 'urgent_care.svg',
			link: '/immediate-care',
			analyticEvent: 'Immediate Care click',
			isActive: true,
		},
	];

	constructor(
		private languageService: LanguageService,
		private intercomService: IntercomService,
		private router: Router,
	) {}
}

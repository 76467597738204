import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicePanelComponent } from './service-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppSharedModule } from '../../app-shared/app-shared.module';
import { TxNativeModule } from '@transifex/angular';
import { DefaultImageModule } from '../default-image/default-image.module';
import { ClientEventTrackerModule } from '../client-event-tracker/client-event-tracker.module';
import { FeedbackBlockModule } from 'src/app/modules/standalone/feedback/feedback-block/feedback-block.module';
import { FeedbackDialogModule } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RippleModule } from 'ripple';
import { ServicePanelCopayComponent } from './service-panel-copay/service-panel-copay.component';
import { ServicePanelAccordionComponent } from './service-panel-accordion/service-panel-accordion.component';
import { ServicePanelCardComponent } from './service-panel-card/service-panel-card.component';
import { ServicePanelHeaderComponent } from './service-panel-header/service-panel-header.component';
import { ServicePanelFooterComponent } from './service-panel-footer/service-panel-footer.component';
import { ServicePanelRelatedServicesComponent } from './service-panel-related-services/service-panel-related-services.component';
import { ServicePanelDeductibleComponent } from './service-panel-deductible/service-panel-deductible.component';
import { ConnectDeductiblesFormHandlerModule } from '../connect-deductibles-form-handler/connect-deductibles-form-handler.module';
import { ServicePanelInfoPanelComponent } from './service-panel-info-frame/service-panel-info-frame.component';
import { ServicePanelTeleheatlhFrameComponent } from './service-panel-telehealth-frame/service-panel-telehealth-frame.component';
import { ServicePanelInformationComponent } from './service-panel-information/service-panel-information.component';
import { ServicePanelTelehealthComponent } from './service-panel-telehealth/service-panel-telehealth.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		ServicePanelComponent,
		ServicePanelCopayComponent,
		ServicePanelAccordionComponent,
		ServicePanelCardComponent,
		ServicePanelHeaderComponent,
		ServicePanelFooterComponent,
		ServicePanelRelatedServicesComponent,
		ServicePanelDeductibleComponent,
		ServicePanelInfoPanelComponent,
		ServicePanelTeleheatlhFrameComponent,
		ServicePanelInformationComponent,
		ServicePanelTelehealthComponent,
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		AppSharedModule,
		TxNativeModule,
		DefaultImageModule,
		ClientEventTrackerModule,
		ConnectDeductiblesFormHandlerModule,
		FeedbackBlockModule,
		FeedbackDialogModule,
		MatTabsModule,
		RippleModule,
		MatTooltipModule,
	],
	exports: [ServicePanelComponent, ServicePanelTelehealthComponent],
})
export class ServicePanelModule {}

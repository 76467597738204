import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError } from 'rxjs';
import { T } from '@transifex/angular';

import { AppError } from '../../../../error-handlers/app-errors';

import CFG from '../../../../config/app-config.json';

import { HealtheeDialogContent } from '../../../../modules/app-shared/healthee-dialog/healthee-dialog.component';

import { AppLanguage, LanguageService } from '../../../../services/language.service';
import { UserService } from '../../../../services/user.service';

import { Capacitor } from '@capacitor/core';

const IFRAME_LOADED_CHECKING_INTERVAL = 100;
const DELAY_TO_DISPLAY_TALON_WIDGET_AFTER_IFRAME_LOADED = CFG.talon.delayToDisplayTalonWidgetAfterTalonIFrameIsLoaded;
const TIME_TO_FORCED_ELEMENT_REFRESH = CFG.talon.timeToForcedPageRefreshInMinutes * 60 * 1000; // minutes to force page refresh if Talon component is displayed on screen (fixes a Talon issue)
const TIME_TO_COME_BACK_TO_THIS_ROUTE = 50;


@Component({
	selector: 'app-widgets',
	templateUrl: './widgets.component.html',
	styleUrls: ['./widgets.component.scss'],
})
export class WidgetsComponent extends HealtheeDialogContent implements OnInit, OnDestroy {
	@ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

	private preferredLanguage: string;
	private scheduledElementRefresh;
	public isElementInDom: boolean; // used to allow the iFrame element to be removed and then added to the DOM every TIME_TO_FORCED_ELEMENT_REFRESH minute (Talon requirement).

	public readonly isMobileApp = Capacitor.isNativePlatform();

	public isLoadingEmitter$ = new BehaviorSubject<boolean>(true);

	public isTalonServiceSearchAvailable: boolean = true;
	public isReportSent: boolean = false;
	public isSendingReport: boolean = false;

	@T('Oops! We cannot send your message at the moment! Please try again later.')
	private userErrors_cannotSendContactUsMessage: string;


	constructor(
		private http: HttpClient,
		private languageService: LanguageService,
		private userService: UserService
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToUserLanguage();
		this.loadPage();
	}

	private subscribeToUserLanguage() {
		this.languageService.appLanguage$.subscribe((language: AppLanguage) => {
			this.preferredLanguage = language.locale;
		});
	}

	private loadPage() {
		this.isElementInDom = true;

		this.setupIframe();
		this.scheduleForcedElementRefresh();
	}

	private setupIframe() {
		this.http
			.post(CFG.apiEndpoints.talonIFrameSrcUrl, { type: '', language: this.preferredLanguage })
			.subscribe({
				next: (response) => {
					const iframe = this.iframe.nativeElement;
					this.checkTokenReceivedFromTalon(response.toString());
					if (!this.isTalonServiceSearchAvailable) this.iFrameLoadingFinished();
					iframe.src = response + '&hideSidebar=true';
					this.checkIfiFrameIsLoaded(iframe);
				},
				error: (error: Error) => {
					return this.displayErrorLoadingTalonWidget(error);
				},
			});
	}

	private checkIfiFrameIsLoaded(iframe: any) {
		const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

		// Check if loading is complete
		if (iframeDoc?.readyState == 'complete') {
			this.iFrameLoadingFinished();
			return;
		}

		// schedule another check of the status again in 100 milliseconds
		setTimeout(() => this.checkIfiFrameIsLoaded(iframe), IFRAME_LOADED_CHECKING_INTERVAL);
	}

	private iFrameLoadingFinished() {
		setTimeout(() => this.isLoadingEmitter$.next(false), DELAY_TO_DISPLAY_TALON_WIDGET_AFTER_IFRAME_LOADED); // delay another 2 seconds to make sure the contents inside the Talon widget are loaded
	}

	private displayErrorLoadingTalonWidget(error: Error) {
		this.isTalonServiceSearchAvailable = false;
		this.iFrameLoadingFinished();
		console.log('Error opening Talon widget:', error.message);
	}

	private scheduleForcedElementRefresh() {
		this.scheduledElementRefresh = setTimeout(
			() => this.refreshByByRemovingAndAddingTheElementToTheDOM(),
			TIME_TO_FORCED_ELEMENT_REFRESH
		);
	}

	private refreshByByRemovingAndAddingTheElementToTheDOM() {
		this.isElementInDom = false;

		setTimeout(() => this.loadPage(), TIME_TO_COME_BACK_TO_THIS_ROUTE);
	}

	private clearScheduledRefresh() {
		clearTimeout(this.scheduledElementRefresh);
	}

	ngOnDestroy() {
		this.clearScheduledRefresh();
	}

	// #region - Ionic spscific code
	/**
	 * Used by ionic. DO NOT DELETE.
	 * this component is displayed twice inside an ionic app:
		(1) under 'Care&Cost', with property widgetType = 'procedureSearch'
		(2) under 'Health Profile' -> 'Deductibles' page, with property widgetType = 'healthPlans'
		The mobile action bar should only be displayed if widgetType === procedureSearchType
	 */
	public ionic_shouldDisplayMobileActionBar() {
	}
	// #endregion

	checkTokenReceivedFromTalon(url: string) {
		const tokenInUrl = url.split('?')[1].split('&')[0];
		if (tokenInUrl.split('=')[0] === 'token') {
			tokenInUrl.split('=')[1] === 'undefined'
				? (this.isTalonServiceSearchAvailable = false)
				: (this.isTalonServiceSearchAvailable = true);
		}
	}

	onSendToFrontIssueReport() {
		this.isSendingReport = true;
		this.userService
			.sendContactUsEmail({
				subject: 'There was an issue connecting to Talon',
				text: '',
			})
			.pipe(
				catchError((error) => {
					if (error instanceof AppError) throw error;
					throw new AppError(this.userErrors_cannotSendContactUsMessage);
				})
			)
			.subscribe({
				next: () => {
					this.isReportSent = true;
				},
			})
			.add(() => {
				this.isSendingReport = false;
			});
	}
}

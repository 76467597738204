import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Deductible, DeductibleNetwork } from '../api/domain/deductible';
import { DeductiblesStoreService } from '../stores/deductibles-store/deductibles-store.service';
import { hasTrulyValue } from '../../utils/utils';
import { MeUserStoreService } from '../stores/me-user-store/me-user-store.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ConnectedDeductiblesService {
	private deductible$: Observable<Deductible> = this.deductiblesStoreService.get();
	public deductiblePlanId$: Observable<string>;

	constructor(
		private deductiblesStoreService: DeductiblesStoreService,
		private meUserStoreService: MeUserStoreService
	) {
		this.deductiblePlanId$ = this.deductible$.pipe(map((deductible) => deductible?.plan));
	}

	public isConnected(): Observable<boolean> {
		return this.deductiblesStoreService.isConnected();
	}

	public getConnectedDeductible(): Observable<Deductible> {
		return this.deductible$;
	}

	public getConnectedOutOfPocketMaximumData(networkFieldName: string): Observable<DeductibleNetwork> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getConnectedDeductible().pipe(
					map((deductible) => deductible?.data?.outOfPocketDetail),
					map((details) => {
						if (hasTrulyValue(details?.[planType]?.[networkFieldName])) {
							return details?.[planType]?.[networkFieldName];
						}

						if (hasTrulyValue(details?.individual?.[networkFieldName])) {
							return details?.individual?.[networkFieldName];
						}

						return details?.family?.[networkFieldName];
					})
				)
			)
		);
	}

	public getConnectedDeductibleCardData(networkFieldName: string): Observable<DeductibleNetwork> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getConnectedDeductible().pipe(
					map((deductible) => deductible?.data?.deductibleDetail),
					map((details) => {
						if (hasTrulyValue(details?.[planType]?.[networkFieldName])) {
							return details?.[planType]?.[networkFieldName];
						}

						if (hasTrulyValue(details?.individual?.[networkFieldName])) {
							return details?.individual?.[networkFieldName];
						}

						return details?.family?.[networkFieldName];
					})
				)
			)
		);
	}

	private defaultCoverageTier(): Observable<string> {
		return this.meUserStoreService.getUserCoverageTier().pipe(map((user) => user.medicalPlanTier));
	}
}

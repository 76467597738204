import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { Contract } from '../../../../models/contract.model';
import dayjs from 'dayjs';
import { Maybe } from '../../../../utils/types/maybe';
import { MeUserStoreService } from '../../../../services/stores/me-user-store/me-user-store.service';
import { Observable, of, shareReplay, switchMap, combineLatest } from 'rxjs';
import { CommonComponent } from '../../../../utils/components/common-component';
import { isNil } from '../../../../utils/is/is-nil';
import { InputOf } from '../../../../utils/input-reflector/input-of';
import { BenefitStoreService } from '../../../../services/stores/benefit-store/benefit-store.service';
import { Benefit } from '../../../../models/benefits.model';
import { map } from 'rxjs/operators';
import { PlanType } from '../../../../models/plan-type.model';
import { delay, getNumberValuesFromDollarAndPercent, isIonic } from '../../../../utils/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { StandardLoadingHandler } from '../../../../utils/loading-handler/standard-loading-handler';
import { blobToBase64 } from '../../../../utils/blob-to-base64';
import { UIService } from '../../../../services/ui.service';
import { T } from '@transifex/angular';
import { TrackingService } from '../../../../services/tracking.service';
import { captureExceptionSentry } from 'src/app/utils/sentry.utils';
import { UnleashService } from 'src/app/services/unleash.service';

const TAG = 'FullCardComponent';
@Component({
	selector: 'app-full-card',
	templateUrl: './full-card.component.html',
	styleUrls: ['./full-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FullCardComponent extends CommonComponent implements OnInit {
	@Input()
	contract: Maybe<Contract>;

	@Input()
	firstName: Maybe<string>;

	@Input()
	lastName: Maybe<string>;

	@ViewChild('cardElement', {
		read: ElementRef,
	})
	cardElementRef: ElementRef;

	@Output()
	unlockClick = new EventEmitter<PlanType>();

	public isExpanded: boolean;
	public buttonText: string;
	public iconName: string;
	public fullName: string;
	public dob: string;
	isMedicalCardDisclaimerEnabled$ = this.unleashService.isEnabled$('ffMedicalCardDisclaimer');

	@T('Something went wrong', {
		token: 'sharingErrorMessage',
	})
	sharingErrorMessage: string;

	shareLoadingHandler = new StandardLoadingHandler();

	memberId$: Observable<Maybe<string>>;

	benefits$: Observable<Array<Benefit & { copayCost: number[]; memberResponsibilityCost?: number[] }>>;

	dob$: Observable<string>;

	constructor(
		private meUserStoreService: MeUserStoreService,
		private benefitStoreService: BenefitStoreService,
		private socialSharing: SocialSharing,
		private uiService: UIService,
		private trackingService: TrackingService,
		private unleashService: UnleashService
	) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'contract'];
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.setButtonValues();
		this.fullName = this.lastName ? [this.firstName, this.lastName].join(' ') : this.firstName;

		const contract$ = this.inputs.one('contract');

		this.memberId$ = contract$.pipe(
			switchMap((contract) =>
				isNil(contract?.planType) ? of(null) : this.meUserStoreService.contractMemberID(contract.planType)
			),
			shareReplay(1)
		);

		this.dob$ = this.meUserStoreService.get().pipe(
			map((me) => (isNil(me) ? 'N/A' : dayjs(me.birthday).format('MM / DD / YY'))),
			shareReplay(1)
		);

		this.benefits$ = contract$.pipe(
			map((contract) => {
				switch (contract.planType) {
					case PlanType.medical:
						return [
							'5d9c3af263507c70ef68860f',
							'5d9c3af763507c70ef688644',
							'60e3bd9e54823252fe1b8f55',
							'5d9c3af263507c70ef68861b',
						];
					case PlanType.dental:
						return ['61e8066090f66c4ccfceb2bd'];
					case PlanType.vision:
						return ['61ef1344d0a2ab18c9b07cbb'];
					default:
						return [];
				}
			}),
			switchMap((services) => combineLatest(services.map((svc) => this.benefitStoreService.get(svc)))),
			map((benefits) => {
				const benefitsWithCosts = benefits
					.filter((benefit) => !isNil(benefit))
					.map((benefit) => {
						let copayCostString;
						let memberResponsibilityCostString;
						let benefitWithCosts;
						if (benefit?.inNetwork?.includes('+')) {
							const costs = benefit.inNetwork.split('+');
							copayCostString = costs[0];
							memberResponsibilityCostString = costs[1];
							benefitWithCosts = {
								...benefit,
								copayCost: getNumberValuesFromDollarAndPercent(copayCostString),
								memberResponsibilityCost:
									getNumberValuesFromDollarAndPercent(memberResponsibilityCostString),
							};
						} else {
							copayCostString = benefit.inNetwork;
							benefitWithCosts = {
								...benefit,
								copayCost: getNumberValuesFromDollarAndPercent(copayCostString),
							};
						}
						return benefitWithCosts;
					})
					.filter((benefit) => !(isNil(benefit.copayCost[0]) && isNil(benefit.copayCost[1])));
				return benefitsWithCosts;
			}),
			shareReplay(1)
		);
	}

	public getBenefitCostValue(benefit: Benefit & { copayCost: number[]; memberResponsibilityCost?: number[] }) {
		let costValue = this.getCostString(benefit.copayCost);
		if (benefit.memberResponsibilityCost) {
			costValue += ` + ${this.getCostString(benefit.memberResponsibilityCost)}`;
		}

		return costValue;
	}

	private getCostString(costs) {
		let costString = '';

		if (costs[0]) {
			costString = `$${costs[0]}`;
		}
		if (!costs[0] && costs[1]) {
			if (costs[1] === 100) {
				costString = '$0';
			} else {
				costString = `${costs[1]}%`;
			}
		}
		return costString;
	}

	public setButtonValues() {
		this.buttonText = this.isExpanded ? 'Hide costs' : 'Show costs';
		this.iconName = this.isExpanded ? 'chevronUp' : 'chevronDown';
	}

	async share(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.trackingService.trackClientEvent('DMC - Share Card', { planType: this.contract?.planType });

		this.shareLoadingHandler.start();

		await delay(1000);

		const element: HTMLElement = this.cardElementRef.nativeElement.cloneNode(true);
		element.style.width = '326px';
		element.style.display = 'block';

		const expansionPanel: HTMLElement = element.querySelector('mat-expansion-panel');
		expansionPanel.style.display = 'none';

		document.body.appendChild(element);

		try {
			const canvas = await html2canvas(element, { scale: 3, useCORS: true });
			document.body.removeChild(element);

			const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
			const fileWidth = 200;
			const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
			const PDF = new jsPDF('p', 'mm', 'a4');
			PDF.addImage(imageGeneratedFromTemplate, 'PNG', 5, 5, fileWidth, generatedImageHeight);
			if (isIonic()) {
				await this.socialSharing.share(null, null, await blobToBase64(PDF.output('blob')));
				this.shareLoadingHandler.finish();
				return;
			}
			PDF.save(`my ${this.contract.planType} card.pdf`);
			this.shareLoadingHandler.finish();
		} catch (e) {
			this.shareLoadingHandler.finish();
			captureExceptionSentry(e, TAG);
			this.uiService.displayAppMessage(this.sharingErrorMessage);
		}
	}

	edit(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.unlockClick.emit(this.contract.planType);
	}

	trackShowCosts() {
		this.trackingService.trackClientEvent('DMC - Show Costs', { planType: this.contract?.planType });
	}

	allBenefitsSubjectToDeductible(
		benefits: (Benefit & { copayCost: number[]; memberResponsibilityCost?: number[] })[]
	): boolean {
		return benefits.every((benefit) => benefit.subjectToDeductibleIn);
	}
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { Copay, CopayCadence, MemberResponsibility } from 'src/app/models/benefits.model';
import { Observable } from 'rxjs';
import { PremiumFeature } from 'src/app/models/premium-feature.model';
import { PremiumFeatureService } from 'src/app/services/premium-feature.service';
import { TelehealthDataService } from 'src/app/services/telehealth-data.service';
import { DeductiblesService } from 'src/app/services/deductibles/deductibles.service';

interface InformationTexts {
	headline?: string;
	text?: string;
	ps?: string;
	showDeductibleTooltip: boolean;
}

@Component({
	selector: 'app-service-panel-information',
	templateUrl: './service-panel-information.component.html',
	styleUrls: ['./service-panel-information.component.scss'],
})
export class ServicePanelInformationComponent extends CommonComponent implements OnInit, OnChanges {
	public isTelehealth$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);

	@Input()
	copay: Copay;

	@Input()
	hasPlanDeductible: boolean;

	@Input()
	mr: MemberResponsibility;

	@Input()
	subjectToDeductible: boolean;

	@Input()
	dependsOnLocation: boolean;

	@Input()
	networkTitle: string;

	@Input()
	serviceId: string;

	@Input()
	deductibleOutOfPocketDetailData: any;

	@Input()
	deductibleDetailsCardData: any;

	public firstLineText: InformationTexts;
	public secondLineText: InformationTexts;

	constructor(
		private premiumFeatureService: PremiumFeatureService,
		public telehealthDataService: TelehealthDataService,
		public deductiblesService: DeductiblesService
	) {
		super();
	}

	public ngOnInit(): void {
		this.deductiblesService
			.initialize(this.networkFieldNameForPlanlevel, this.networkFieldNameForConnected)
			.subscribe(() => {
				this.generateTexts();
			});
	}

	public ngOnChanges(): void {
		this.generateTexts();
	}

	private generateTexts(): Promise<void> {
		this.resetTexts();

		if (this.deductiblesService.isOutOfPocketMaximumMet) {
			this.firstLineText.text = 'This service is fully covered by your payer';
			this.firstLineText.ps = 'You met your out-of-pocket maximum';
			return;
		}

		if (this.dependsOnLocation) {
			this.firstLineText.text = 'Coverage depends on location';
			return;
		}

		if (this.copay.label) {
			this.firstLineText.text = this.copay.label;
			return;
		}

		// Check if copay amount is 0 and member responsibility is 100%
		if ((this.copay.amount === 0 || !this.copay.amount) && this.mr.percent && this.mr.percent === 100) {
			this.firstLineText.text = `You'll pay 100% of this service cost`;
			this.firstLineText.ps = 'Your insurance does not cover this service';
			this.firstLineText.showDeductibleTooltip = false;
			this.subjectToDeductible = false;
			return;
		}

		// Check if copay amount is 0 and member responsibility is 0%
		if ((this.copay.amount === 0 || !this.copay.amount) && (this.mr.percent === 0 || !this.mr.percent)) {
			this.firstLineText.text = 'This service is fully covered by your payer';
			this.firstLineText.ps = 'Applies always, regardless of your deductible status';
			this.subjectToDeductible = false;
		}

		if (
			((this.mr.dollar && this.mr.dollar > 0) || (this.mr.percent && this.mr.percent > 0)) &&
			((this.copay.amount && this.copay.amount > 0) || (this.copay.upToLimit && this.copay.upToLimit > 0))
		) {
			this.setCopayText();
			this.setCoinsuranceText(false);
		} else if (this.copay.amount && this.copay.amount > 0) {
			this.setCopayText();
		} else if ((this.mr.dollar && this.mr.dollar > 0) || (this.mr.percent && this.mr.percent > 0)) {
			this.setCoinsuranceText(true);
		}

		this.setDeductibleInformaton();
	}

	private setDeductibleInformaton(): void {
		if (this.subjectToDeductible) {
			if (this.deductiblesService.hasPlanLevelDeductible) {
				this.firstLineText.headline = 'Before deductible';
				this.secondLineText.headline = 'After deductible';
			} else {
				this.firstLineText.headline = 'Before out-of-pocket maximum';
				this.secondLineText.headline = 'After out-of-pocket maximum';
			}
			this.firstLineText.text = `You'll pay 100% of the service cost`;
		} else {
			this.secondLineText.ps = `Applies always, regardless of your deductible status`;
			this.subjectToDeductible = false;
		}
	}

	private resetTexts() {
		this.firstLineText = { showDeductibleTooltip: true };
		this.secondLineText = { showDeductibleTooltip: true };
	}

	private setCoinsuranceText(onlyCoinsurance: boolean): void {
		const value = !this.mr.dollar ? `${this.mr.percent}%` : `$${this.mr.dollar}`;

		if (!onlyCoinsurance) {
			this.secondLineText.text += this.mr.cadence
				? `, then ${value} on any additional costs`
				: ` and additional ${value} of this service cost`;
		} else {
			this.secondLineText.text = `You'll pay ${value} of this service cost`;
		}

		if (this.mr.cadence) {
			this.secondLineText.text += ` per ${this.mr.cadence}`;
		}

		if (this.mr.amount) {
			this.secondLineText.text += ` up to a maximum of $${this.mr.amount}`;
		}

		if (this.mr.additionalPercent) {
			this.secondLineText.text += `. Then, you'll pay ${this.mr.additionalPercent}%`;
			this.secondLineText.text += ' of the remaining cost';
		}
	}

	private setCopayText(): void {
		if (this.copay.amount > 0) {
			this.secondLineText.text = `You'll pay $${this.copay.amount} ${
				this.copay.upToLimit ? ' - $' + this.copay.upToLimit : ''
			} for this service`;
		} else {
			this.secondLineText.text = `You'll pay up to $${this.copay.upToLimit}`;
		}

		if (this.copay.unit) {
			this.secondLineText.text += ` per each ${this.copay.unit}`;
		}

		switch (this.copay.cadence) {
			case CopayCadence.Admission:
				this.secondLineText.text += ` per ${this.copay.cadenceCount} ${this.copay.cadence}${
					this.copay.cadenceCount > 1 ? 's' : ''
				}`;
				break;
			case CopayCadence.FirstNDays:
				this.secondLineText.text += ` during the first ${this.copay.cadenceCount} days`;
				break;
		}
	}

	public get inNetwork(): boolean {
		return this.networkTitle !== 'Out network';
	}

	private get networkFieldNameForPlanlevel(): string {
		return this.inNetwork ? 'inNetwork' : 'outNetwork';
	}

	private get networkFieldNameForConnected(): string {
		return this.inNetwork ? 'inNetwork' : 'outOfNetwork';
	}
}

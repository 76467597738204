<header id="page-topbar">
	<div class="navbar-header">
		<div class="navbar-subheader">
			<div *ngIf="isDefaultLayout$ | async; else nonEligibleLogo" class="navbar-left-side">
				<button
					*ngIf="!isMobileApp"
					type="button"
					class="md-d-none btn btn-sm px-3 header-item waves-effect"
					id="vertical-menu-btn"
					(click)="toggleSidenavMenu()"
				>
					<img class="menu-icon" src="/assets/images/icons/menu.svg" alt="menu" />
				</button>

				<ng-container *ngIf="!isMobileApp">
					<div class="topbar__title-container">
						<ripple-button
							*ngIf="displayBackButton$ | async"
							[hierarchy]="hierarchy.tertiary"
							leadingIcon="arrowLeft"
							[isElevated]="true"
							[isCurrentColor]="true"
							(onClick)="goBack()"
						></ripple-button>

						<label *ngIf="pageTitle" class="font-size-20 md-d-inline-block mb-0">
							{{ pageTitle }}
						</label>
					</div>
				</ng-container>

				<img
					*ngIf="isMobileApp"
					class="d-inline-block sm-d-none width-fit max-width-40 ml-16"
					src="/assets/images/logo.svg"
					alt="Logo"
				/>
			</div>
			<ng-template #nonEligibleLogo class="navbar-left-side">
				<img
					class="d-inline-block width-fit max-width-50 ml-16"
					src="/assets/images/logo.svg"
					alt="Logo"
				/>
			</ng-template>
			<div class="navbar-right-side">
				<app-user-avatar *ngIf="userInitials$ | async as initials" class="user-avatar"></app-user-avatar>
			</div>
		</div>
	</div>
</header>

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	combineLatestWith,
	debounceTime,
	firstValueFrom,
	map,
	Observable,
	of,
	shareReplay,
	startWith,
	Subject,
	Subscription,
	takeUntil,
} from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { T } from '@transifex/angular';
import { ObjectUtils } from '../../../../../utils/object';
import { isNil } from '../../../../../utils/is/is-nil';
import { HealtheeDialogService } from '../../../../../services/healthee-dialog.service';
import {
	DEFAULT_FORM_VALUES,
	googlePlaceOptions,
	mobileFilterersOptions,
	SearchEntity,
	SearchFields,
	SearchType,
	isSubStrStartWith,
	ATTRIBUTES_LABELS,
	ProvidersSearchResults,
	DEFAULT_LOCATION,
} from '../../helpers/providers-search.helper';
import { RibbonEntitiesStoreService } from '../../../../../services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { UIService } from '../../../../../services/ui.service';

import { TrackingService } from 'src/app/services/tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { ProvidersSearchService } from '../../../../../services/providers-search/providers-search.service';
import { SearchAutocompleteService } from '../../../../../services/providers-search/search-autocomplete.service';
import { RxjsUtils } from '../../../../../utils/rxjs';
import { SearchBarService } from '../../../../../services/providers-search/search-bar.service';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { GooglePlaceDirective } from '../../../../standalone/ngx-google-places-autocomplete/google-place.directive';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { ExternalApiService } from 'src/app/services/external-api.service';
import { UserService } from 'src/app/services/user.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { CompanyStoreService } from '../../../../../services/stores/company-store/company-store.service';
import { RibbonHealthService } from 'src/app/services/ribbon.service';

const OON_CONTROL_NAME = 'outOfNetwork';

enum SearchFieldEnum {
	'provider' = 'provider',
	'location' = 'location',
}

@Component({
	selector: 'app-providers-search-bar',
	templateUrl: './providers-search-bar.component.html',
	styleUrls: ['./providers-search-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ProvidersSearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
	SearchType = SearchType;
	SearchFieldEnum = SearchFieldEnum;
	public mobileFilterersOptions = mobileFilterersOptions;
	public googlePlaceOptions = googlePlaceOptions;
	public displayErrors: boolean;

	public hasAppointmentBookingFeature = false;

	public isMobileView: boolean = false;
	public isMobileCollapsed: boolean = false;
	private isLocationFromAutcompleteList: boolean = false;
	public mobileSearchOpen: SearchFieldEnum | null = null;
	private pacContainerElements: NodeListOf<Element>;
	public userSelectedAddress: string;

	public recentSearches$: BehaviorSubject<string[]> = this._searchBarService.recentSearches$;
	public rbpNetworkSearch$: BehaviorSubject<boolean> = this._searchBarService._rbpNetworkSearch$;
	public networkStructure$: Observable<NetworkStructure> = this._providersSearchService.searchNetworkStructure$;
	private paramSub$: Subscription;
	private networkStructureSub$: Subscription = new Subscription();
	public rbpApproved: boolean = true;

	get isMobile$(): Observable<boolean> {
		return this.uiService.isMobile$;
	}

	@ViewChild('inputNative') inputNative: ElementRef<HTMLInputElement>;
	@ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
	@ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) autoGroup: MatAutocompleteTrigger;
	@ViewChild('locationInput', { read: MatAutocompleteTrigger }) locationAutoComplete: MatAutocompleteTrigger;
	@ViewChild('placesRef') placesRef: GooglePlaceDirective;
	@ViewChild('filterWrapperRef', { static: false }) filterWrapperRef;
	@ViewChild('filterRef', { static: false }) filterRef;
	@ViewChild('dropdownContainer') dropdownContainer: ElementRef;

	private _unsubscribe = new Subject<void>();
	public isOverflowing = false;
	private _searchParams: SearchFields;
	get searchParams(): SearchFields {
		return this._searchParams;
	}
	@Input() set searchParams(params: SearchFields) {
		this._searchParams = params;
		this.onParamsChange(this._searchParams);
	}

	@Input() appearance: 'partial' | 'full' = 'full';
	@Input() showFilters: boolean = true;
	@Input() startMobileCollapsed: boolean = false;
	@Input() selectedTopSearch: any;
	@Input() showMobileMapView: boolean;
	@Input() showEmptyState: boolean = false;
	@Input() resetFiltersToggle: boolean;
	@Input() isResultPage: boolean = false;

	@Output() searchEvent: EventEmitter<SearchFields> = new EventEmitter();
	@Output() selectTopRatedProviderEvent: EventEmitter<null> = new EventEmitter();
	@Output() applyFiltersEvent: EventEmitter<any> = new EventEmitter();
	@Output() showMobileMapViewChange = new EventEmitter<boolean>();

	public get shorterContent(): boolean {
		return this.appearance === 'partial';
	}

	public allOptions$;

	@T('Filter Providers')
	modalTitle: string;

	public filtersCount$!: Observable<number>;
	private healtheeServices$ = this.ribbonEntitiesStoreService.getHealthServices();
	private locationTypes$ = this.ribbonEntitiesStoreService.getLocationTypes();
	private treatments$ = this.ribbonEntitiesStoreService.getTreatments();
	private conditions$ = this.ribbonEntitiesStoreService.getConditions();
	private searchByNeeds$ = this.ribbonEntitiesStoreService.getSearchByNeeds();

	public searchForm = this._searchBarService.getSearchFormGroup();
	public filtersForm = this._searchBarService.getFiltersFormGroup();
	insuranceGroups$ = this.userService.userData$.pipe(
		map((userData) =>
			userData.company.insuranceGroups?.map((group) => {
				return { value: group };
			})
		)
	);

	public setMobileSearchAndFocus(state: SearchFieldEnum | null): void {
		this.addMobileSearchClass();

		if (!this.showAutocompleteLoader) {
			this.mobileSearchOpen = state;

			setTimeout(() => {
				if (state === null) return;

				state === SearchFieldEnum.location
					? this.locationInput.nativeElement.focus()
					: this.inputNative.nativeElement.focus();
			}, 200);
		}
	}

	private addMobileSearchClass(): void {
		// add custom classname to ngx-google-places-autocomplete dropdown for full screen styling
		this.pacContainerElements = document.querySelectorAll('.pac-container');

		if (this.pacContainerElements) {
			this.pacContainerElements.forEach((element: Element) => {
				this._renderer2.addClass(element, 'full-screen-address');
			});
		}
	}

	public setMobileInputsCollapsed(state): void {
		this.isMobileCollapsed = state;
	}

	public attributeGroups: any[] = [
		{
			name: ATTRIBUTES_LABELS.SPECIALTIES,
			nameInExploreMode: ATTRIBUTES_LABELS.MORE_SPECIALTIES,
			type: SearchEntity.Provider,
			options: this.healtheeServices$.pipe(
				startWith([]),
				combineLatestWith(this.searchForm.controls.value.valueChanges.pipe(startWith(''))),
				map(([services, value]) =>
					services.map((service) => service.ribbon.key).filter((service) => isSubStrStartWith(service, value))
				)
			),
			id: `${SearchEntity.Provider}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.CONDITIONS,
			type: SearchEntity.Condition,
			options: this.conditions$.pipe(
				startWith([]),
				combineLatestWith(this.searchForm.controls.value.valueChanges.pipe(startWith(''))),
				map(([conditions, value]) => {
					return conditions.filter((condition) => isSubStrStartWith(condition.label, value));
				})
			),
			id: `${SearchEntity.Condition}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.TREATMENTS,
			type: SearchEntity.Treatment,
			options: this.treatments$.pipe(
				startWith([]),
				combineLatestWith(this.searchForm.controls.value.valueChanges.pipe(startWith(''))),
				map(([treatments, value]) => {
					return treatments.filter((treatment) => isSubStrStartWith(treatment.label, value));
				})
			),
			id: `${SearchEntity.Treatment}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.FACILITIES,
			type: SearchEntity.Facility,
			options: this.locationTypes$.pipe(
				startWith([]),
				combineLatestWith(this.searchForm.controls.value.valueChanges.pipe(startWith(''))),
				map(([types, value]) => types.filter((location) => isSubStrStartWith(location.text, value)))
			),
			id: `${SearchEntity.Facility}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.AUTOCOMPLETE,
			type: 'autocomplete',
			options: this._searchAutocompleteService.results$.pipe(
				RxjsUtils.isNotNil(),
				map((data) => {
					this.showAutocompleteLoader = false;
					this._changeDetectorRef.detectChanges();
					return this._searchAutocompleteService.getFormattedData(data);
				})
			),
			id: `${SearchEntity.Provider}_${SearchType.Free}`,
		},
		{
			name: ATTRIBUTES_LABELS.TOP_SPECIALTIES,
			type: SearchEntity.Provider,
			options: of([
				'Primary Care Provider - PCP',
				'Dentist',
				'Family Medicine',
				'Optometrist',
				'Obstetrics and Gynecology',
			]),
			id: `${SearchEntity.Provider}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.MENTAL_HEALTH,
			type: SearchEntity.Provider,
			options: of([
				'Registered Nurse - Psych/Mental Health',
				'Psychiatry and Neurology - Child and Adolescent Psychiatry',
				'Psychiatry and Neurology - Clinical Neurophysiology',
				'Counselor - Mental Health',
			]),
			id: `${SearchEntity.Provider}_${SearchType.Options}`,
		},
		{
			name: ATTRIBUTES_LABELS.SEARCH_BY_NEED,
			type: SearchEntity.SearchByNeed,
			options: this.searchByNeeds$.pipe(
				startWith([]),
				combineLatestWith(this.searchForm.controls.value.valueChanges.pipe()),
				map(([types, value]) => types.filter((location) => isSubStrStartWith(location.text, value)))
			),
			id: `${SearchEntity.SearchByNeed}_${SearchType.Options}`,
		},
	];

	public readonly scores = this.ribbonHealthService.scores();
	public readonly languages = this.ribbonHealthService.languages();
	public readonly distances = this.ribbonHealthService.distances();
	public readonly networks = this.ribbonHealthService.networks();
	public readonly genders = this.ribbonHealthService.genders();
	public readonly NetworkStructure = NetworkStructure;
	private hasTalonSupport$: Observable<boolean>;
	private isInit: boolean = false;
	public showAutocompleteLoader: boolean = false;
	public showLocationLoader: boolean = false;
	public isFirstOptionHighlighted: boolean = false;
	public shouldDisplayBackButton: boolean = false; //this._activatedRoute.snapshot.queryParamMap.has('referrer');
	private providersResponse$: Observable<ProvidersSearchResults> = this._providersSearchService.results$;
	public multiNetworks: object[] = [
		{ id: 'true', display: 'Favorite Network' },
		{ id: 'false', display: 'All Networks' },
		{ id: 'null', display: 'Out-of-Network' },
	];

	constructor(
		private ribbonHealthService: RibbonHealthService,
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		public healtheeDialogService: HealtheeDialogService,
		public trackingService: TrackingService,
		public uiService: UIService,
		private router: Router,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private _providersSearchService: ProvidersSearchService,
		private _renderer2: Renderer2,
		private _searchAutocompleteService: SearchAutocompleteService,
		private _searchBarService: SearchBarService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _currentLocationService: CurrentLocationService,
		private _activatedRoute: ActivatedRoute,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private externalApiService: ExternalApiService,
		private userService: UserService,
		private companyStoreService: CompanyStoreService
	) {
		this.hasTalonSupport$ = this.externalApiService.isUserSupportedByTalon();
	}

	async ngOnInit(): Promise<void> {
		this.getParamsFromUrl();

		this.isMobileView = UIService.isMobileDeviceScreen(window);
		if (this.startMobileCollapsed) this.setMobileInputsCollapsed(true);

		this.filtersCount$ = this.filtersForm.valueChanges.pipe(
			startWith(this.filtersForm.value),
			map((formVal) => ObjectUtils.values(formVal).filter((val) => !isNil(val)).length),
			shareReplay(1)
		);

		this.searchForm.controls.value.valueChanges
			.pipe(RxjsUtils.isNotNil(), distinctUntilChanged())
			.subscribe((val) => {
				this.showAutocompleteLoader = val.length > 0;
				this.searchForm.controls.entity.reset();
				this.searchForm.controls.searchType.reset();
			});

		this.setAutocompleteSearch();

		combineLatest(this.attributeGroups.map((group) => group.options))
			.pipe(takeUntil(this._unsubscribe))
			.subscribe();

		this.companyStoreService
			.get()
			.pipe(
				tap(
					(company) =>
						(this.hasAppointmentBookingFeature =
							company?.showAppointmentBooking === undefined || company?.showAppointmentBooking)
				)
			)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe();

		this.providersResponse$.subscribe((providersResponse) => {
			if (!providersResponse.networkName) return;
			this.multiNetworks = [
				{ id: 'true', display: providersResponse.networkName },
				{ id: 'false', display: 'Other Networks' },
				{ id: 'null', display: 'Out of Network' },
			];
			this._changeDetectorRef.detectChanges();
		});

		if (!this.isResultPage) {
			await this.checkCurrentLocation();
		}
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.selectedTopSearch && !changes.selectedTopSearch.firstChange) {
			if (this.selectedTopSearch?.ribbon?.key) {
				this.searchForm.patchValue({
					entity: SearchEntity.Provider,
					searchType: SearchType.Options,
					value: this.selectedTopSearch.ribbon.key,
				});
			} else if (this.selectedTopSearch?.text) {
				this.searchForm.patchValue({
					entity: SearchEntity.SearchByNeed,
					searchType: SearchType.Options,
					value: this.selectedTopSearch.text,
				});
			} else {
				return;
			}

			this.displayErrors = false;
			this.showAutocompleteLoader = false;
			this._changeDetectorRef.detectChanges();

			await this.checkCurrentLocation();

			if (this.searchForm.controls.address.value) {
				this.onFormSubmit();
			}
		} else if (changes.resetFiltersToggle && !changes.resetFiltersToggle.firstChange) {
			this.handleDialogFiltersActions(true);
		}
	}

	private getParamsFromUrl(): void {
		if (this.shorterContent) return;

		this.paramSub$ = this._activatedRoute.queryParamMap.pipe(RxjsUtils.isNotNil()).subscribe({
			next: (params) => {
				const value = params.get('value');
				const entity = params.get('entity');

				if (!value || !entity) {
					return;
				}

				this.searchForm.patchValue({
					value: value,
					entity: entity,
					searchType: SearchType.Options,
				});
				this._changeDetectorRef.detectChanges();
			},
		});
	}

	ngAfterViewInit(): void {
		this.isInit = true;

		this.autoGroup.autocomplete.optionGroups.changes.subscribe(() => {
			this.highlightFirstOptionIfNeeded();
		});
	}

	private setAutocompleteSearch(): void {
		combineLatest([
			this.searchForm.controls.value.valueChanges.pipe(
				RxjsUtils.isNotNil(),
				debounceTime(300),
				filter((value) => !!value)
			),
			this.healtheeServices$.pipe(map((services) => services.map((service) => service.ribbon.key))),
		])
			.pipe(
				tap(() => {
					this.showAutocompleteLoader = true;
				}),
				switchMap(([value, services]) =>
					this._searchAutocompleteService.search(value, this.searchForm.controls.address.value, services)
				)
			)
			.subscribe();
	}

	public toggleMobileMapView(): void {
		this.setMobileInputsCollapsed(true);
		if (this.showMobileMapView) {
			this._providersSearchService.setActiveLocationIndex(null);
		}
		this.showMobileMapViewChange.emit(!this.showMobileMapView);
	}

	public onGoogleLocationChange() {
		this.isLocationFromAutcompleteList = false;
		this.locationAutoComplete.closePanel();
	}

	public deleteInputText(event): void {
		event.stopPropagation();
		this.searchForm.controls.value.reset('');
		this.setMobileSearchAndFocus(SearchFieldEnum.provider);
		this.trackingService.trackClientEvent('PS - Clear');
		this.showAutocompleteLoader = false;
	}

	public deleteLocationText(event): void {
		event.stopPropagation();
		if (this.isMobileView) {
			const dropdown = document.querySelector('.full-screen-address');
			this._renderer2.setStyle(dropdown, 'display', 'none');
			this.locationAutoComplete.openPanel();
		}

		this.searchForm.controls.address.reset();
		this.setMobileSearchAndFocus(SearchFieldEnum.location);
	}

	public onAddressChange(address: string, skipSubmit: boolean = false): void {
		this.locationAutoComplete.closePanel();
		this.mobileSearchOpen = null;
		if (isNil(address) || !address || this.userTypedAndNotSelectFromOptions(address)) {
			this.setMobileSearchAndFocus(SearchFieldEnum.location);
			this.locationInput.nativeElement.click();
			this.locationInput.nativeElement.blur();
			this.locationInput.nativeElement.focus();
			return;
		}
		this.userSelectedAddress = address;
		this.addToRecentSearches(address);
		this.searchForm.controls.address.setValue(address);
		this._searchBarService.isCurrentLocation$.next(false);
		this.filtersForm.get(OON_CONTROL_NAME).patchValue(false);

		const { value } = this.searchForm.value;
		if ((!value && address && !this.isResultPage && this.showEmptyState) || skipSubmit) {
			this._changeDetectorRef.detectChanges();
			return;
		}

		this.onFormSubmit();
	}

	public onOptionSelected(event): Promise<void> {
		const value = event.option?.value?.trim();
		if (!value) return;

		const [entity, searchType]: [SearchEntity, SearchType] = event.option?.id.split('_') || undefined;
		this.searchForm.patchValue({ entity, value, searchType });
		this.displayErrors = false;
		this.showAutocompleteLoader = false;
		this._changeDetectorRef.detectChanges();
		this.rbpNetworkSearch$.next(true);
		this.filtersForm.get(OON_CONTROL_NAME).patchValue(false);
		this.onFormSubmit();
	}

	userTypedAndNotSelectFromOptions(address: string): boolean {
		return (
			!this.searchForm.controls.address.pristine &&
			(isNil(this.placesRef.place) || this.placesRef.place?.formatted_address !== address) &&
			!this.isLocationFromAutcompleteList
		);
	}

	public onInputClick(): void {}

	public onFormSubmit(): void {
		if (!this.isInit) return;

		const { address } = this.searchForm.value;
		let { value, entity, searchType } = this.searchForm.value;
		if (!value) {
			this.displayErrors = true;
			this.setMobileSearchAndFocus(SearchFieldEnum.provider);
			this._changeDetectorRef.detectChanges();
			return;
		}

		[searchType, entity, value] =
			searchType && entity
				? [searchType, entity, value]
				: this._searchBarService.getSearchTypeAndEntityIfNotSelected(this.autoGroup.autocomplete, value);

		this.displayErrors = false;

		if (isNil(address) || !address || this.userTypedAndNotSelectFromOptions(address)) {
			this.setMobileSearchAndFocus(SearchFieldEnum.location);
			this.locationInput.nativeElement.click();
			this.locationInput.nativeElement.blur();
			this.locationInput.nativeElement.focus();
			return;
		}

		this.networkStructureSub$ = this.networkStructure$.subscribe((networkStructure) => {
			if (networkStructure === NetworkStructure.RBP) {
				const isRbpApproved = this.filtersForm.get('rbpApproved').value;
				if (isRbpApproved !== null) {
					this.filtersForm.get(OON_CONTROL_NAME).patchValue(!isRbpApproved);
					this.rbpNetworkSearch$.next(isRbpApproved);
				}
			} else {
				this.rbpNetworkSearch$.next(false);
			}
			if (networkStructure === NetworkStructure.MN || networkStructure === NetworkStructure.MTN) {
				const mnFavoriteSearch = this.filtersForm.get('mnFavoriteSearch').value;
				mnFavoriteSearch === 'null'
					? this.filtersForm.get(OON_CONTROL_NAME).patchValue(true)
					: this.filtersForm.get(OON_CONTROL_NAME).patchValue(false);
			}
		});

		this.networkStructureSub$.unsubscribe();

		const formData: SearchFields = {
			distance: DEFAULT_FORM_VALUES.distance,
			outOfNetwork: DEFAULT_FORM_VALUES.outOfNetwork,
			entity,
			searchType,
			value,
			address,
			page: 1,
			...this.filtersForm.value,
		};

		this.submitValues(formData);
		this.setMobileSearchAndFocus(null);
		this.setMobileInputsCollapsed(true);
		this.locationInput.nativeElement.blur();
		this.inputNative?.nativeElement.blur();
		this.autoGroup.closePanel();
	}

	private addToRecentSearches(search: string): void {
		this._searchBarService.addToRecentSearches(search);
	}

	private submitValues(formData: SearchFields): void {
		this.searchEvent.emit(formData);
	}

	private onParamsChange(params: SearchFields): void {
		this.isInit = false;
		const {
			address,
			searchType,
			value,
			entity,
			page,
			distance,
			gender,
			language,
			minRating,
			outOfNetwork,
			onlineBookingOnly,
			insuranceGroup,
			mnFavoriteSearch,
		} = params;

		this.searchForm.setValue({ address, searchType, value, entity, page });
		this.filtersForm.controls.distance.setValue(
			this.distances.find((option) => option.id === distance)?.id ||
				(distance ? null : DEFAULT_FORM_VALUES.distance)
		); // when distance is from map area, it should be empty
		this.filtersForm.controls.gender.setValue(this.genders.find((option) => option.id === gender)?.id || null);
		this.filtersForm.controls.language.setValue(
			this.languages.find((option) => option.title === language)?.title || null
		);
		this.filtersForm.controls.minRating.setValue(this.scores.find((option) => option.id === minRating)?.id || null);
		this.filtersForm.controls.onlineBookingOnly.setValue(
			typeof onlineBookingOnly === 'boolean' ? onlineBookingOnly : onlineBookingOnly.toLowerCase() === 'true'
		);
		this.filtersForm.controls.outOfNetwork.setValue(this.networks.find((option) => option.id === outOfNetwork)?.id);
		this.filtersForm.controls.insuranceGroup.setValue(insuranceGroup || null);
		this.filtersForm.controls.rbpApproved.setValue(
			!this.networks.find((option) => option.id === outOfNetwork)?.id || null
		);
		this.filtersForm.controls.mnFavoriteSearch.setValue(mnFavoriteSearch || null);
		this.filtersForm.markAsPristine();
		this.isInit = true;
	}

	public onResetFilter(): void {
		this.filtersForm.reset();
		this.filtersForm.patchValue(DEFAULT_FORM_VALUES);
		this.trackingService.trackClientEvent('PS - Filter: Reset filters');
	}

	public onClickBack() {
		this.uiService.navigateBack(this.providersSearchUrlsService.baseUrl);
	}

	public onClickGetCurrentLocation() {
		this.getCurrentLocation();
	}

	public onClickSetRecentLocation(location: string) {
		this.setLocationFromAutocompleteList(location);
	}

	private setLocationFromAutocompleteList(location, skipSubmit: boolean = false): void {
		this.isLocationFromAutcompleteList = true;
		this.onAddressChange(location, skipSubmit);
		this.rbpNetworkSearch$.next(true);
	}

	private highlightFirstOptionIfNeeded(): void {
		const firstGroupOption = this.autoGroup.autocomplete.optionGroups.first?.label.toLowerCase();

		this.isFirstOptionHighlighted = firstGroupOption !== ATTRIBUTES_LABELS.AUTOCOMPLETE;
		this._changeDetectorRef.detectChanges();
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();

		if (this.pacContainerElements) {
			this.pacContainerElements.forEach((element: Element) => {
				this._renderer2.removeClass(element, 'full-screen-address');
			});
		}

		this.paramSub$?.unsubscribe();
		this.networkStructureSub$.unsubscribe();
	}

	public goBack() {
		this.router.navigateByUrl(this._activatedRoute.snapshot.queryParams.referrer);
	}

	public selectTopRatedProviderHandler(service) {
		this.selectTopRatedProviderEvent.emit(service);
	}

	private async checkCurrentLocation(): Promise<void> {
		this.showLocationLoader = true;
		const recentSearchLocations = await firstValueFrom(this._searchBarService.recentSearches$);

		if (recentSearchLocations[0]) {
			this.setLocationFromAutocompleteList(recentSearchLocations[0], true);
			this._searchBarService.isCurrentLocation$.next(true);
		} else {
			const isApproved = await this._currentLocationService.checkPermission();
			if (isApproved) {
				await this.getCurrentLocation();
			} else {
				this.setLocationFromAutocompleteList(DEFAULT_LOCATION, true);
				this._searchBarService.isCurrentLocation$.next(true);
			}
		}

		this.showLocationLoader = false;
		this._changeDetectorRef.detectChanges();
	}

	private getCurrentLocation(): Promise<string> {
		this._changeDetectorRef.detectChanges();

		return this._currentLocationService.getCurrentPlaceInfo().then((place) => {
			this.setLocationFromAutocompleteList(place?.formatted_address, true);
			this._searchBarService.isCurrentLocation$.next(true);
			return place?.formatted_address;
		});
	}

	public handleDialogFiltersActions(resetFilters: boolean): void {
		if (resetFilters) {
			this.onResetFilter();
		}

		this.applyFiltersEvent.emit(this.filtersForm.value);

		if (this.showEmptyState) return;

		this.onFormSubmit();
	}
}

<div *ngIf="showDeductible" class="subject-to-deductible-container">
	<div class="unexpanded">
		<div
			*ngIf="deductiblesService.isConnectedToDeductible && deductiblesService?.networkData?.remaining !== 0"
			class="information-container"
		>
			<div class="information-wrapper">
				<div class="pre-icon">
					<ripple-icon
						[matTooltip]="
							deductiblesService.hasPlanLevelDeductible
								? 'Your deductible is the amount you\'ll have to pay out of pocket before your health plan kicks in and starts covering medical costs. Deductibles reset every year.'
								: 'The amount you\'ll pay out-of-pocket during a plan year for covered medical services. Once you reach your maximum, your health plan will pay 100% of your covered services'
						"
						[size]="ICON_SIZE.size16"
						name="dollarCircle"
					></ripple-icon>
				</div>

				<p>
					{{ 'You haven’t reached your' | translate }}
					{{ !deductiblesService.hasPlanLevelDeductible ? 'out-of-pocket maximum' : 'deductible' }} yet
				</p>

				<div class="information-icon">
					<ripple-icon
						[matTooltip]="
							deductiblesService.hasPlanLevelDeductible
								? 'Your deductible is the amount you\'ll have to pay out of pocket before your health plan kicks in and starts covering medical costs. Deductibles reset every year.'
								: 'The amount you\'ll pay out-of-pocket during a plan year for covered medical services. Once you reach your maximum, your health plan will pay 100% of your covered services'
						"
						[size]="ICON_SIZE.size12"
						name="infoCircle"
					></ripple-icon>
				</div>
			</div>

			<a class="show-more-link" (click)="toggleContainer()">{{
				!isContainerExpanded ? 'Show Deductible' : 'Hide Deductible'
			}}</a>
		</div>

		<div
			*ngIf="deductiblesService.isConnectedToDeductible && deductiblesService?.networkData?.remaining === 0"
			class="information-container"
		>
			<div class="information-wrapper">
				<div class="pre-icon">
					<ripple-icon [color]="ICON_COLOR.green" [size]="ICON_SIZE.size16" name="checkCircle"></ripple-icon>
				</div>

				<p>
					{{ 'You have reached your' | translate }}
					{{ !deductiblesService.hasPlanLevelDeductible ? 'out-of-pocket maximum' : 'deductible' }}
				</p>

				<div class="information-icon">
					<ripple-icon
						[matTooltip]="
							deductiblesService.hasPlanLevelDeductible
								? ('Your deductible is the amount you\'ll have to pay out of pocket before your health plan kicks in and starts covering medical costs. Deductibles reset every year.'
								  | translate)
								: ('The amount you\'ll pay out-of-pocket during a plan year for covered medical services. Once you reach your maximum, your health plan will pay 100% of your covered services'
								  | translate)
						"
						[size]="ICON_SIZE.size12"
						name="infoCircle"
					></ripple-icon>
				</div>
			</div>

			<a class="show-more-link" (click)="toggleContainer()">{{
				!isContainerExpanded ? ('Show Deductible' | translate) : ('Hide Deductible' | translate)
			}}</a>
		</div>

		<div class="information-container" *ngIf="deductiblesService.isConnectedToDeductible === false">
			<div class="information-wrapper">
				<div>
					{{
						'To see your deductible and how much more you’ll pay before the rest of your coverage kicks in,'
							| translate
					}}
					<a class="connect-insurance-account" (click)="openDeductibleConnectionForm()">{{
						'connect your insurance account.' | translate
					}}</a>
				</div>
			</div>
		</div>
	</div>

	<div class="expanded" *ngIf="isContainerExpanded">
		<div class="network-status-container">
			<div class="network-status">
				<span class="status-circle" [class.oon]="!inNetwork"></span>
				<span class="status-text">{{ networkTitle | translate | titlecase }} {{ 'payment' | translate }}</span>
			</div>
		</div>
		<div class="status-bar-container">
			<ripple-progress-bar [percentageFilled]="deductiblesService?.spendtercentage + '%'"></ripple-progress-bar>
		</div>
		<div class="deductible-information-container">
			<div class="consumed">
				<span class="amount">{{ (deductiblesService?.networkData?.spend | currency) || 'N/A' }}</span>
				<span class="label">{{ 'Consumed' | translate | titlecase }}</span>

				<span *ngIf="deductiblesService.hasPlanLevelDeductible" class="out-of-pocket-maximum">{{
					'Out of pocket maximum' | translate
				}}</span>
			</div>
			<div class="remaining">
				<span class="amount">{{ (deductiblesService?.networkData?.spend | currency) || 'N/A' }}</span>
				<span class="label"
					>{{ (deductiblesService?.networkData?.remaining | currency) || 'N/A' }}
					{{ 'left' | translate }}</span
				>
				<div class="consumed-remaining" *ngIf="deductiblesService.hasPlanLevelDeductible">
					<span class="consumed">{{
						(deductiblesService?.networkDeductibleOOPMData?.spend | currency) || 'N/A'
					}}</span>
					<span>/</span>
					<span>{{ (deductiblesService?.networkDeductibleOOPMData?.maximum | currency) || 'N/A' }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #deductiblesForm>
	<div class="connect-form-modal">
		<p class="connect-form-description">
			{{ connectFormModalDescription }}
		</p>
		<app-connect-deductibles-form-handler
			(connected)="healtheeDialogService.close()"
		></app-connect-deductibles-form-handler>
	</div>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contract } from '../../../../models/contract.model';
import { PlanType } from '../../../../models/plan-type.model';

const ASSETS_PATH = '/assets/images/health-profile';

@Component({
	selector: 'app-locked-card',
	templateUrl: './locked-card.component.html',
	styleUrls: ['./locked-card.component.scss'],
})
export class LockedCardComponent implements OnInit {
	@Input()
	contract: Contract;

	@Output()
	unlockClick = new EventEmitter<PlanType>();

	public buttonText: string;

	public assetsPath = ASSETS_PATH;

	constructor() {}

	ngOnInit(): void {
		this.buttonText = 'Unlock your ' + this.contract.planType + ' card';
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { T } from '@transifex/angular';

import FEATURES from '../../../../config/feature-flags.json';
import { isIonic } from '../../../../utils/utils';

import { DISABLED_BACK_NAVIGATION, UIService } from '../../../../services/ui.service';
import { ZoeMessagesService } from '../../../../services/zoe-messages.service';
import { LanguageService } from '../../../../services/language.service';

const EMPTY_TITLE = '';
// TODO: Change title's style - to be centerd all the time (regrdless if left or right icons appear)
@Component({
	selector: 'app-mobile-action-bar',
	templateUrl: './mobile-action-bar.component.html',
	styleUrls: ['./mobile-action-bar.component.scss'],
})
export class MobileActionBarComponent implements OnInit {
	public readonly DISABLED_BACK_NAVIGATION = DISABLED_BACK_NAVIGATION;
	public readonly isIonic = isIonic();
	public readonly isAndroid = Capacitor.getPlatform() === 'android';

	@Input() showBottomBorder: boolean = true;
	@Input() titleOverride: string = EMPTY_TITLE;
	@Input() showNotificationBell: boolean = true;

	public unreadMessagesCount$: Observable<number>;
	public ionicBackOverrideLink: string;
	public translatedTitle: string;
	public isNotificationsCenterOpened: boolean;

	private translationsObject = {};

	@T('Ask Zoe', { _key: 'ask_zoe' })
	private title_chat: string;

	@T('My Profile', { _key: 'my_profile' })
	private title_profile: string;

	@T('My Benefits', { _key: 'my_benefits' })
	private title_benefits: string;

	@T('Care & Costs', { _key: 'care_and_costs' })
	private title_care: string;

	@T('Health Profile', { _key: 'health_profile' })
	private title_health: string;

	@T('Session Summary', { _key: 'session_summary' })
	private title_session: string;

	@T('FAQ', { _key: 'faq' })
	private title_faq: string;

	@T('Privacy Policy', { _key: 'privacy_policy' })
	private title_privacy: string;

	constructor(
		public uiService: UIService,
		private languageService: LanguageService,
		private messagesService: ZoeMessagesService,
	) {}

	ngOnInit(): void {
		this.subscribeToLocale();
		this.subscribeToUnreadMessages();
	}

	private subscribeToLocale() {
		this.languageService.appLanguage$.subscribe(() => {
			this.translationsObject = {
				my_profile: this.title_profile,
				ask_zoe: this.title_chat,
				my_benefits: this.title_benefits,
				care_and_costs: this.title_care,
				health_profile: this.title_health,
				session_summary: this.title_session,
				faq: this.title_faq,
				privacy_policy: this.title_privacy,
			};
			this.subscribeToDataFromActivatedRoute();
		});
	}

	private subscribeToDataFromActivatedRoute() {
		this.uiService.activatedRouteData$.subscribe((data) => {
			const title = this.titleWasSetAsAProperty() ? this.titleOverride : data?.title;
			this.ionicBackOverrideLink = data?.ionicNativeBackOverrideLink;
			this.translatedTitle = this.translateTitle(title);
		});
	}

	private translateTitle(str) {
		return this.translationsObject[str];
	}

	private titleWasSetAsAProperty() {
		return this.titleOverride !== EMPTY_TITLE;
	}

	private subscribeToUnreadMessages() {
		this.unreadMessagesCount$ = this.messagesService.unreadMessagesCount$;
	}

	public navigateBack() {
		if (this.pageHasExplicitBackNavigationOverrideLink()) {
			this.uiService.navigateBack(this.ionicBackOverrideLink);
			return;
		}
		this.uiService.mobileGoBack();
	}

	private pageHasExplicitBackNavigationOverrideLink() {
		return !!this.ionicBackOverrideLink;
	}
}

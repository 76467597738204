<div class="zocdoc-container">
	<h2 class="centered-item">
		Appointments booking is provided by<br />
		Zocdoc
	</h2>
	<img
		src="../../assets/images/providers-search/zocdoc-auth.png"
		alt="Zocdoc authentication icon"
		class="zocdoc-auth-image"
	/>
	<h3 class="centered-item">
		Our partners at Zocdoc just need to confirm your<br />
		email, this will only take a few seconds
	</h3>
	<ripple-button
		[trailingIcon]="ARROW_RIGHT"
		[label]="'Continue' | translate"
		(click)="authenticate()"
		size="md"
	></ripple-button>
</div>

import { Component, OnInit } from '@angular/core';
import { map, Observable, combineLatest } from 'rxjs';

import CFG from '../../config/app-config.json';
import { useIonicFile } from '../../utils/utils';

import { AppManagerService } from '../../services/app-manager.service';
import { ZoeMessagesService } from '../../services/zoe-messages.service';
import { AppLanguage, LanguageService } from '../../services/language.service';
import { UserService } from 'src/app/services/user.service';
import { PreviousRouteService } from 'src/app/services/PreviousRouteService';
import { UIService } from '../../services/ui.service';
import { AuthService } from '../account/login/auth.service';
import { UnleashService } from '../../services/unleash.service';

@Component({
	selector: 'app-main-layout',
	templateUrl: useIonicFile('./main-layout.component.html', './main-layout.component.ionic.html'),
	styleUrls: [useIonicFile('./main-layout.component.scss', './main-layout.component.ionic.scss')],
})
export class MainLayoutComponent implements OnInit {
	public showScreenOverlay$: Observable<boolean>;
	public unreadMessagesCount$: Observable<number>;
	public isHidden: boolean = false;
	public showChat: boolean = true;
	public isTopbarHidden$ = this.uiService.route$.pipe(
		map((url) => {
			if (!url) return false;

			return this.uiService.shouldRouteHideTopBar(url) || this.uiService.isFullscreenPCT;
		})
	);

	get showNonEligible$(): Observable<boolean> {
		return this.userService.isUserEligible$.pipe(map((isEligible) => !isEligible));
	}

	constructor(
		private appManager: AppManagerService,
		private languageService: LanguageService,
		private messagesService: ZoeMessagesService,
		private userService: UserService,
		private authService: AuthService,
		private previousRouteService: PreviousRouteService,
		private uiService: UIService,
		private unleashService: UnleashService
	) {}

	ngOnInit() {
		this.subscribeToLocale();
		this.performAppSetup();
		this.subscribeToUnreadMessages();
		this.handlePhoneVerificationFlow();
		this.listenToLayout();
	}

	private listenToLayout() {
		combineLatest([this.showNonEligible$, this.uiService.route$]).subscribe(([showNonEligible, url]) => {
			this.isHidden = showNonEligible || (url && this.uiService.shouldRouteHideBottomBar(url));
		});
	}

	private subscribeToLocale() {
		this.languageService.appLanguage$.subscribe((appLanguage: AppLanguage) => {
			this.showChat = appLanguage.locale !== 'es';
		});
	}

	private performAppSetup() {
		this.appManager.initMainLayout();
	}

	private subscribeToUnreadMessages() {
		this.unreadMessagesCount$ = this.messagesService.unreadMessagesCount$;
	}

	private async handlePhoneVerificationFlow() {
		const comesFromLogin = this.previousRouteService.getPreviousUrl() === '/account/login';

		if (comesFromLogin) {
			 // check if user doesn't have a phone number
			if (!(await this.authService.isOtpSmsActivated()) || !(await this.authService.userHasVerifiedPhone())) {
				this.appManager.openVerifyPhoneNumber();
			}
		}
	}

	/**
	 * Returns true if an element with selector was tapped
	 * @param  {string} selector - element selector
	 * @param  {any} event - should be a 'touchend' event
	 * @param  {number} touchDuration - Touch duration from 'touchstart'
	 */
	elementTapped(selector: string, event: any, touchDuration: number) {
		const isAScreenTap = touchDuration < CFG.behavior.touchDurationToConsiderASwipe;

		if (event.type !== 'touchend') return false;

		if (event.target.closest(selector) === null) return false;

		return isAScreenTap;
	}
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import CFG from '../config/app-config.json';

import { UserService } from '../services/user.service';

// Add suffixes or prefixes for routes to be passed "as-is", without adding an auth header
export const ignoredRouteSuffixes = [
	CFG.apiEndpoints.userLogin_Credentials,
	CFG.apiEndpoints.userLoginToken,
	CFG.apiEndpoints.userResetPassword,
	CFG.apiEndpoints.contactRegistration,
	CFG.apiEndpoints.userLogin2FA,
	CFG.apiEndpoints.userRegistrationInfoWithExternalId.split('/').pop(), // take the last portion of this url. It has a /:externalId/ changeable part
	CFG.apiEndpoints.biometricLogin,
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.urlIsAnIgnoredRoute(req.url)) {
			return next.handle(req);
		}

		return this.userService.token$.pipe(
			take(1),
			switchMap((token) => {
				if (!token) {
					return next.handle(req);
				}

				const requestWithAuthHeader = this.addAuthorizationHeader(req, token);
				return next.handle(requestWithAuthHeader);
			})
		);
	}

	private urlIsAnIgnoredRoute(url: string) {
		let found = false;

		ignoredRouteSuffixes.forEach((routeSuffix) => {
			if (url.endsWith(routeSuffix)) found = true;
		});

		return found;
	}

	private addAuthorizationHeader(req: HttpRequest<any>, token: string) {
		return req.clone({
			headers: req.headers.set('authorization', 'Bearer ' + token),
		});
	}
}

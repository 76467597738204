export interface AppLanguage {
	locale: string;
	localeTagOnTransifex: string;
	displayName: string;
	displayShortName: string;
	displayOrder: number;
}

export const LANGUAGE_LIST: { [key: string]: AppLanguage } = {
	// This list should be correlated with the language list returned by the server:
	// (1) insurightsServer: server/services/languageService.js, and
	// (2) The 'availableLanguages' that is on the user's company object:
	// 		user.data.company.availableLanguages
	// company.availableLanguages list is defined by the admin-dashboard and admin-server,
	// and as of 24.08.2024, new system-languages have to be added manually to the code.
	en: {
		locale: 'en',
		localeTagOnTransifex: 'en',
		displayName: 'English',
		displayShortName: 'EN',
		displayOrder: 1
	},

	es: {
		locale: 'es',
		localeTagOnTransifex: 'es',
		displayName: 'Español',
		displayShortName: 'ES',
		displayOrder: 2
	},

	jhs: {
		locale: 'jhs',
		localeTagOnTransifex: 'en_AL', // JHS English is disguised as en_AL (Albanian English) on Transifex
		displayName: 'English JHS',
		displayShortName: 'EN',
		displayOrder: 3
	},
}

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_APP_LANG: AppLanguage = LANGUAGE_LIST[DEFAULT_LOCALE];
export const DEFAULT_SUPPORTED_LANGS: AppLanguage[] = [DEFAULT_APP_LANG];

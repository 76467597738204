import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICON_SIZE } from 'ripple';
import { Subscription, combineLatest, map, take, tap } from 'rxjs';
import { ServiceCoverage } from 'src/app/models/service-coverage.model';
import { HomePageService } from 'src/app/modules/home-page/services/home-page.service';
import { TelehealthService } from 'src/app/modules/telehealth/services/telehealth.service';
import { AllServicesStoreService } from 'src/app/services/stores/all-services-store/all-services-store.service';
import { TelehealthDataService, telehealthSerivcesIDFromDB } from 'src/app/services/telehealth-data.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
  selector: 'app-service-panel-telehealth',
  templateUrl: './service-panel-telehealth.component.html',
  styleUrls: ['./service-panel-telehealth.component.scss']
})
export class ServicePanelTelehealthComponent implements OnChanges, OnDestroy {

  @Input() serviceId: string;

  @Output()
  relatedBenefitClick = new EventEmitter<string>();

  @Output()
	closeClick = new EventEmitter<void>();

  subscription: Subscription;
  selectedService;
  ServiceCoverage = ServiceCoverage;
  relatedServices = [];
  allTelehealthServices;
  telehealthSerivcesIDFromDB = telehealthSerivcesIDFromDB;
  userBenefitsMap$ = this.homePageService.userBenefitsMap$;
  public rippleSize: typeof ICON_SIZE = ICON_SIZE;

  constructor(private telehealthDataService: TelehealthDataService, private serviceStoreService: AllServicesStoreService, private telehealthService: TelehealthService, private trackingService: TrackingService, private homePageService: HomePageService) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('serviceId' in changes) {
      this.subscription = combineLatest([this.telehealthDataService.getTelehealthFreeServices().pipe(take(1)), this.serviceStoreService.byId(telehealthSerivcesIDFromDB)]).subscribe(([services, serviceFromDB]) => {
        this.allTelehealthServices = services;

        this.selectedService = services.filter(s => s._id === this.serviceId)[0];

        const relatedServices = services.filter(s => s._id !== this.serviceId);
        this.relatedServices = [...relatedServices, serviceFromDB];

      });
    }
  }


  openTelehealth() {
    this.telehealthService.onOpenTelehealth();
    this.trackingService.trackClientEvent('Telehealth modal started', {Source: 'service details modal'});
  }
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

import { RibbonFacility } from '../modules/main-layout/care-costs/helpers/providers.helpers';

export interface RibbonPhoneNumber {
	phone: string;
	details: string;
}

export interface RibbonFax {
	fax: string;
	details: string;
}

export interface RibbonLocation {
	address: string;
	phoneNumbers: RibbonPhoneNumber[];
	faxes: RibbonFax[];
	googleMapsLink: string;
}

// The following interfaces were automatically generated from the mock api

export interface Geo {
	latitude: number;
	longitude: number;
}

export interface Parameters {
	page: number;
	page_size: number;
	total_count: number;
	sort_by: string;
	address: string;
	distance: number;
	geo: Geo;
	location_within_distance: boolean;
	insurance_ids: string[];
	specialty_ids: string[];
	_excl_fields: string[];
}

export interface Education2 {
	name: string;
	uuid: string;
}

export interface Education {
	type?: any;
	year: number;
	education: Education2;
}

export interface Specialty {
	uuid: string;
	taxonomy_code: string;
	board_specialty?: any;
	board_sub_specialty?: any;
	non_md_specialty: string;
	non_md_sub_specialty: string;
	provider_name: string;
	colloquial?: any;
	display: string;
	taxonomy_1: string;
	taxonomy_2: string;
	taxonomy_3: string;
	provider_type: string;
	is_primary: boolean;
}

export interface PhoneNumber {
	phone: string;
	details: string;
}

export interface AddressDetails {
	zip: string;
	city: string;
	state: string;
	street: string;
	address_line_1: string;
	address_line_2?: any;
}

export interface Location {
	name: string;
	uuid: string;
	address: string;
	latitude: number;
	longitude: number;
	confidence: number;
	phone_numbers: PhoneNumber[];
	faxes: RibbonFax[];
	location_types: string[];
	address_details: AddressDetails;
	google_maps_link: string;
	distance: number;
	version_id?: string;
}

export interface OnlineProfile {
	name?: string;
	url: string;
}

export interface PanelDemographics {
	ages?: string[];
	sexes?: string;
	ages_string?: string;
}

export interface Condition {
	uuid: string;
	display: string;
}

export interface Treatment {
	uuid: string;
	display: string;
}

export interface ClinicalArea {
	uuid: string;
	display: string;
	conditions: Condition[];
	treatments: Treatment[];
}

export interface Condition2 {
	uuid: string;
	display: string;
}

export interface Treatment2 {
	uuid: string;
	display: string;
}

export interface Cost {
	cost_index?: number;
}

export interface Quality {
	experience_index: number;
}

export interface Procedure {
	cost: Cost;
	uuid: string;
	display: string;
	quality: Quality;
}

export interface Performance { }

export interface RibbonDoctor {
	npi: string;
	first_name: string;
	middle_name?: string;
	last_name: string;
	age?: number;
	insurances?: { uuid: string }[];
	gender?: string;
	ratings_count?: number;
	ratings_avg?: number;
	degrees?: string[];
	educations?: Education[];
	providerType?: string[];
	specialties?: Specialty[];
	provider_types?: string[];
	languages?: string[];
	locations?: Location[];
	online_profiles?: OnlineProfile[];
	panel_demographics?: PanelDemographics;
	clinical_areas?: ClinicalArea[];
	conditions?: Condition2[];
	treatments?: Treatment2[];
	procedures?: Procedure[];
	performance?: Performance;
	distance?: number;

	fullName?: string;
	averageCost?: number;
	isInNetwork?: boolean;
	specialtyDisplayNames?: string[];
}

export interface NormalizedRibbonDoctor extends RibbonDoctor {
	urlKey: ProvidersSearchUrlKey.PROVIDER;
	urlId: string;
	name: string;
	gender?: string;
	goToDetailsPage: () => void;
	address: string;
	street?: string;
	state: string;
	city: string;
	specialty: string;
	is_pcp?: boolean;
	locations?: any;
	location?: string;
}

export interface NormalizedRibbonFacility extends RibbonFacility {
	urlKey: ProvidersSearchUrlKey.FACILITY;
	urlId: string;
	goToDetailsPage: () => void;
	address: string;
	street?: string;
	state: string;
	city: string;
	specialty?: string | null;
	specialties?: Specialty[];
	is_pcp?: boolean;
	gender?: never;
	locations?: any;
	location?: string;
}

export type NormalizedRibbonProvider = NormalizedRibbonDoctor | NormalizedRibbonFacility;

export interface RibbonPCTData {
	doctors: RibbonDoctorDetails;
	facilities: RibbonFacilityDetails;
}

export interface RibbonDoctorDetails {
	parameters: Parameters;
	data: RibbonDoctor[];
}

export interface RibbonFacilityDetails {
	parameters: Parameters;
	data: RibbonFacility[];
}
export interface RibbonResponse {
	networkStatus: string;
	providers: RibbonDoctorDetails;
}

export interface ProviderSearchResults {
	ribbonProviders: RibbonDoctor[];
	address: string;
}
export interface RibbonService {
	id: string;
	key: string;
}
export enum ServiceType {
	Ribbon = 'ribbon',
	Healthee = 'healthee',
}
export interface RibbonProvidersInputs {
	address: string;
	serviceId: string;
	serviceType: ServiceType;
	minRating?: number;
	language?: string;
	minEfficiencyIndex?: number;
	page?: number;
	pageSize?: number;
	distance?: number;
	gender?: string;
	name?: string;
	onlineBookingOnly?: boolean;
}

export interface RibbonProvidersSearchRequest {
	entity?: string;
	zipCode?: string;
	name: string;
	pageNb: number;
}

export enum ProvidersSearchUrlKey {
	PROVIDER = 'specialist',
	FACILITY = 'facility',
}

<!-- ionic-only action-bar. Not rendered on web version -->
<app-mobile-action-bar></app-mobile-action-bar>
<nav
	*ngIf="shouldShowTabs"
	mat-tab-nav-bar
	class="tabs-header"
	[tabPanel]="tabPanel"
	[attr.mat-align-tabs]="tabsAlignment"
	mat-stretch-tabs="false"
>
	<a
		mat-tab-link
		[routerLink]="['/care-and-costs/providers']"
		[active]="activeTabId === Tab.Providers"
		(selectedTabChange)="onSelectedTabChange(Tab.Providers)"
	>
		{{ 'Provider Search' | translate | titlecase }}
	</a>
	<a
		mat-tab-link
		*ngIf="(hasTalonSupport$ | async) && hasPricecomparisonToolEnabled"
		[routerLink]="['/care-and-costs/services']"
		[active]="activeTabId === Tab.Services"
		(selectedTabChange)="onSelectedTabChange(Tab.Services)"
	>
		{{ 'Cost Estimation' | translate | titlecase }}
	</a>
</nav>
<mat-tab-nav-panel #tabPanel>
	<router-outlet></router-outlet>
</mat-tab-nav-panel>

import { EnvironmentConfigurationInterface } from './environment.interface';

export const environment: EnvironmentConfigurationInterface = {
	production: true,
	environmentType: 'web',
	envName: 'production',
	baseUrl: 'https://app.healthee.co',
	unleashURL: 'https://ff.healthee.co/proxy',
	language: 'en',
	mixpanelProjectToken: 'c5dbe1cf489b3d90a30f54c57ab44e0a',
	mixpanelProjectTokenPCT: '912d8e4dc42f76abf7c263b6ea765ad5',
	clientLogApiUrl: 'https://logging-hooks.healthee.co/app-logs',
	intercomAppId: 'w7upgqig',
	enableSentry: true,
	zocdocClientId: 'abUauHsyPAXEQwKzNVypQ4YbYho39OlA',
	zocdocBaesUrl: 'https://auth.zocdoc.com/authorize',
	zocdocAudience: 'DeveloperApiPatient',

	// node.js backend port
	serverPort: 443,

	/**
	 * Development only -
	 * @useAltServer - use to run nodejs server on a server other than the angular server
	 * @altServer - change to match the hostname and port of the machine running the nodejs server
	 */
	useAltServer: true,
	debugAnalytics: false,

	altServer: {
		hostname: 'https://api.healthee.co',
		port: 443,
	},

	mpiServer: {
		hostname: 'https://mpi-api.healthee.co',
		port: 443,
	},

	servicesSearchURL: 'https://search-service.healthee.co/search/services',
	gipitpotURL: 'https://chat-api.healthee.co/demo',

	authAdminApi: 'https://auth-admin-api.healthee.co',
	cognito: {
		userPoolId: 'IYn2Gbcnj',
		// healthee client
		defaultClient: '4911km2junlips4td6gb450535',
		cognitoDomain: 'auth.healthee.co',
		hybridStorage: true,
		clients: {
			trinet: {
				vendorPrettyName: 'Trinet',
				idpName: 'trinet2',
				appSubDomain: 'trinet',
				userPoolWebClientId: '3n7bt1ru1a36sut1idu177m4o3',
				oauth: {
					scope: ['openid'],
					responseType: 'code',
					redirectSignIn: 'https://trinet.healthee.co/account/login/',
					redirectSignOut: 'https://trinet.healthee.co/account/login/',
				},
			},
			mpi: {
				vendorPrettyName: 'MPI',
				idpName: 'mpi',
				appSubDomain: 'mpi-app',
				userPoolWebClientId: '43vslifvvebnmq1d2eq5olccsf',
				sessionStorage: true,
				oauth: {
					scope: ['openid'],
					responseType: 'token',
					redirectSignIn: 'https://app.healthee.co/account/login/',
					redirectSignOut: 'https://todo.com/',
				},
			},
		},
	},

	recaptcha: {
		siteKey: '6Lfn9wwqAAAAAL7jMvA1A0vKyuVb_tr5OJjAgSW1',
	},
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

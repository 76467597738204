import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_SIZE } from 'ripple';
import { TelehealthService } from 'src/app/modules/telehealth/services/telehealth.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
	selector: 'app-service-panel-teleheatlh-frame',
	templateUrl: './service-panel-telehealth-frame.component.html',
	styleUrls: ['./service-panel-telehealth-frame.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePanelTeleheatlhFrameComponent {
	public readonly ICON_SIZE = ICON_SIZE;

	@Input()
	subjectToDeductible: boolean;

	constructor(private telehealthService: TelehealthService, private trackingService: TrackingService) {}

	public onTeaserClick(buttonName): void {
		this.telehealthService.onOpenTelehealth();
		this.trackingService.trackClientEvent('Benefits Telehealth modal start', { Action: buttonName });
	}
}

import { Component, OnInit, Input } from '@angular/core';
import { combineLatest, take, map, Observable, shareReplay } from 'rxjs';
import { T } from '@transifex/angular';

import { PlanClassType } from 'src/app/models/domain/plan-class-type';
import { CommonComponent } from 'src/app/utils/components/common-component';
import { InputOf } from 'src/app/utils/input-reflector/input-of';
import { isNil } from 'src/app/utils/is/is-nil';
import { Maybe } from 'src/app/utils/types/maybe';
import { RxjsUtils } from '../../../../../utils/rxjs';
import { UserPlanDataStoreService } from '../../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { TrackingService } from '../../../../../services/tracking.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { BenefitsService } from 'src/app/services/benefits.service';

@Component({
	selector: 'app-benefit-wrapper',
	templateUrl: './benefit-wrapper.component.html',
	styleUrls: ['./benefit-wrapper.component.scss'],
})
export class BenefitWrapperComponent extends CommonComponent implements OnInit {
	@Input() message: any;
	@Input() mobileView: boolean;

	public showPlantypeIndicator$: Observable<boolean>;

	public planClassType$: Observable<Maybe<PlanClassType>>;

	public readonly PlanClassType = PlanClassType;
	public readonly NetworkStructure = NetworkStructure;

	@T('Subject to Deductible')
	subjectToDeductibleText: string;

	@T('Not subject to Deductible')
	notSubjectToDeductibleText: string;

	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private trackingService: TrackingService,
		private benefitsService: BenefitsService
	) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return ['message'];
	}

	ngOnInit(): void {
		super.ngOnInit();

		const planData$ = this.userPlanDataStoreService.get().pipe(shareReplay(1));
		const message$ = this.inputs.one('message');

		this.planClassType$ = combineLatest([planData$, message$]).pipe(
			map(([planData, message]) => {
				if (isNil(planData) || isNil(message)) {
					return null;
				}
				if (
					message.networkStructure === NetworkStructure.MN ||
					message.networkStructure === NetworkStructure.MTN
				) {
					message.additionalNetworks = this.benefitsService.setMnBenefitsNetworkPriority(
						planData.contract?.additionalNetworks,
						message.additionalNetworks
					);
				}
				const currentContract = planData[message.planType === 'medical' ? 'contract' : message.planType];
				return currentContract?.planClass;
			}),
			shareReplay(1)
		);

		this.showPlantypeIndicator$ = combineLatest([
			planData$.pipe(RxjsUtils.isNotNil(), take(1)),
			this.planClassType$,
		]).pipe(map(([data, classType]) => (!!data.dental || !!data.vision) && classType === PlanClassType.EOC));
	}

	getDeductibleImgSrc(subjectToDeductible: boolean): string {
		return subjectToDeductible
			? '/assets/images/icons/gallery/service-viewer/layout/red.svg'
			: '/assets/images/icons/gallery/service-viewer/layout/green.svg';
	}

	getDeductibleTooltipText(subjectToDeductible: boolean): string {
		return subjectToDeductible ? this.subjectToDeductibleText : this.notSubjectToDeductibleText;
	}

	calcBenefitIcon(text: string): string {
		let url = '/assets/images/icons/gallery/service-viewer/layout/';
		if (text.match(/depends on location/i))
			// case incentive search
			return (url += 'dollar.svg');
		if (text.match(/Negotiated charge/i))
			// case incentive search
			return (url += 'negotiated.svg');
		if (text.match(/No Charge/i))
			// case incentive search
			return (url += 'no-charge.svg');
		if (text.match(/Not Covered/i)) return (url += 'umbrella-99.svg');
		if (text.includes('$')) return (url += 'dollar.svg');
		if (text.includes('%')) {
			const val = +text.match(/\d/g).join(''); // remove non-digits and cast to number
			if (val <= 25) return (url += 'umbrella-25.svg');
			else if (val <= 50) return (url += 'umbrella-50.svg');
			else if (val <= 80) return (url += 'umbrella-80.svg');
			else if (val <= 99) return (url += 'umbrella-99.svg');
			else return (url += 'umbrella-100.svg');
		}

		return url;
	}

	onTrackTooltip(planType) {
		this.trackingService.trackClientEvent('Tooltip', { name: `covered by ${planType} plan`, source: 'chat' });
	}
}

import { MFAMethods } from '../services/user.service';
import { UserOpenEnrollmentData } from './open-enrollment.model';
import { PremiumFeatures } from './premium-feature.model';

export interface UserData {
	uid: string;
	firstName: string;
	lastName?: string;
	avatar?: string;
	hasMembershipCard: boolean;
	token: string;
	mfaToken: string;
	externalId?: string;
	companyLogo: string;
	companyName: string;
	accountStatus: string;
	nickname: string;
	birthday: Date;
	gender: string;
	weight: number;
	height: string;
	smoking: boolean;
	zipcode: string;
	role?: UserRole;
	esiEligible?: boolean;
	// isEligible is deprecated, use esiEligible instead
	isEligible?: boolean;
	isTalonTpa?: boolean;

	company: {
		id: string;
		name: string;
		logo: string;
		availableLanguages: [];
		showIntercom: boolean;
		showPlanSelection: boolean;
		premiumFeatures: PremiumFeatures;
		insuranceGroups?: string[];
		PCTOnlyMode?: boolean;
		fsaEligibility?: boolean;
	};
	preferredLanguage: string;
	defaultMFAMethod?: MFAMethods;
	email: string;
	phoneNumber?: string;

	features: any;
	openEnrollmentData: UserOpenEnrollmentData;
	subscribeToAppointmentNotifications?: boolean;
	communicationSettings: {
		marketingMessages?: boolean;
	};
	integrations: {
		doctegrity: {
			isDoctegrityRegistered: boolean;
		};
	};
	isHomePagePreventiveCareInit?: boolean;
	dependents?: any[];
}

export enum UserRole {
	Admin = 'admin',
	Employee = 'employee',
}

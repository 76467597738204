import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIService } from '../../../services/ui.service';
import { TrackingService } from '../../../services/tracking.service';
import { SubscriptionBag } from '../../../utils/subscription-bag';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { ExternalApiService } from 'src/app/services/external-api.service';
import { getUrlWoParams } from 'src/app/utils/utils';
import { CompanyStoreService } from '../../../services/stores/company-store/company-store.service';

enum Tab {
	Providers = 'Providers',
	Services = 'Services',
}

@Component({
	selector: 'app-care-and-costs',
	templateUrl: './care-and-costs.component.html',
	styleUrls: ['./care-and-costs.component.scss'],
})
export class CareAndCostsComponent implements OnInit, OnDestroy {
	Tab = Tab;

	get tabsAlignment(): string {
		return this.isMobileView ? 'center' : 'start';
	}

	public links = {
		[Tab.Providers]: {
			id: Tab.Providers,
			trackingId: 'Provider-Click-Doctors-Tab',
			url: '/care-and-costs/providers',
		},
		[Tab.Services]: {
			id: Tab.Services,
			trackingId: 'Provider-Click-Services-Tab',
			url: '/care-and-costs/services',
		},
	};

	public activeTabId: Tab = this.links[Tab.Providers].id;
	private isMobileView = false;
	private subscriptionBag = new SubscriptionBag();
	hasTalonSupport$: Observable<boolean>;

	private urlsWithTabs = [
		'/care-and-costs',
		this.links[Tab.Providers].url,
		`${this.links[Tab.Providers].url}/results`,
		this.links[Tab.Services].url,
	];

	shouldShowTabs: boolean = true;

	public hasPricecomparisonToolEnabled: boolean = false;
	private unsubscribe = new Subject<void>();

	constructor(
		private trackingService: TrackingService,
		private uiService: UIService,
		private externalApiService: ExternalApiService,
		private companyStoreService: CompanyStoreService
	) {
		this.hasTalonSupport$ = this.externalApiService.isUserSupportedByTalon();
	}

	ngOnInit(): void {
		this.isMobileView = UIService.isMobileDeviceScreen(window);
		this.subscriptionBag.add = this.uiService.route$.subscribe({
			next: (url) => {
				const urlWoParams = getUrlWoParams(url);
				this.activeTabId =
					Object.values(this.links).find((link) => link.url === urlWoParams)?.id || Tab.Providers;
				this.shouldShowTabs = this.urlsWithTabs.includes(urlWoParams);
			},
		});

		this.companyStoreService
			.get()
			.pipe(
				tap(
					(company) =>
						(this.hasPricecomparisonToolEnabled =
							company?.showPriceComparisonTool === undefined || company?.showPriceComparisonTool)
				)
			)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe();
	}

	onSelectedTabChange(tabId: Tab) {
		const trackingId = this.links[tabId].trackingId;
		if (trackingId === 'Provider-Click-Services-Tab') {
			this.trackingService.trackClientEvent('Care & Costs Service search start');
		}
	}

	ngOnDestroy(): void {
		this.subscriptionBag.dispose();
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}

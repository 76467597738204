import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { T } from '@transifex/angular';
import dayjs from 'dayjs';

import { environment } from '../../../../../environments/environment';

import FEATURES from '../../../../config/feature-flags.json';
import CFG from '../../../../config/app-config.json';
import { FfStagingBotConversation } from '../../../../config/feature-flags/ff-staging-bot-conversation';
import { delay, isIonic, useIonicFile } from '../../../../utils/utils';
import { RxjsUtils } from '../../../../utils/rxjs';
import { isNil } from '../../../../utils/is/is-nil';

import { ChatData, ChatMessage, ChatUserLogData, DeductibleChat } from '../../../../models/chat.model';
import { PlanClassType } from '../../../../models/domain/plan-class-type';
import { ChatResponse, ChatResponseWithUserInput, ChatAndIntercomResponse } from '../../../../models/chat-response.model';
import { UserRole } from '../../../../models/user-data';
import { FeatureFlag } from '../../../../models/feature-flag.model';

import { TrackingService } from './../../../../services/tracking.service';
import { AppViewMode, UIService } from './../../../../services/ui.service';
import { ChatSession, ConversationBot, ConversationService } from '../../../../services/conversation.service';
import { AppManagerService } from '../../../../services/app-manager.service';
import { AppLanguage, LanguageService } from '../../../../services/language.service';
import { IntercomService } from '../../../../services/intercom.service';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { UserService } from '../../../../services/user.service';
import { UnleashService } from '../../../../services/unleash.service';
import { ProvidersSearchService } from '../../../../services/providers-search/providers-search.service';
import {
	TelehealthDataService,
	telehealthSerivceTitles,
	telehealthSerivcesIDFromDB,
} from '../../../../services/telehealth-data.service';
import { TelehealthService } from '../../../../modules/telehealth/services/telehealth.service';
import { AllServicesStoreService } from '../../../../services/stores/all-services-store/all-services-store.service';

import { CommonComponent } from '../../../../utils/components/common-component';
import { BubbleDialogComponent } from '../../../../dialogs/bubble-dialog/bubble-dialog.component';
import { WidgetType } from '../../main-layout-shared/talon-widget/talon-widget.component';
import { TalonWidgetDialogComponent } from '../../main-layout-shared/talon-widget-dialog/talon-widget-dialog.component';
import { BenefitWrapperComponent } from './coverage-card/benefit-wrapper.component';

import { DateBadge } from './helpers/date-badge';
import { addDateBadges } from './helpers/app-date-badge';

const DOCTORS_SEARCH_TEXT = 'Find Providers';
const MAX_QUERY_LENGTH = 256;
const ICONS_PATH = {
	STOP: '../../../../../assets/images/icons/stop-feed.svg',
	PLAY: '../../../../../assets/images/icons/play-feed.svg',
	MARK_QUOTE_INITIAL: '../../../../../assets/images/icons/mark-init.svg',
	MARK_QUOTE_CLICKED: '../../../../../assets/images/icons/mark-clicked.svg',
};

@Component({
	selector: 'app-newchat',
	templateUrl: './newchat.component.html',
	styleUrls: [useIonicFile('./newchat.component.scss', './newchat.component.ionic.scss')],
	animations: [
		trigger('fade-in', [
			state(
				'out',
				style({
					opacity: '0',
				})
			),
			state(
				'in',
				style({
					transform: 'translateX(0px)',
				})
			),
			// transition('out => in', animate('800ms ease-in', keyframes([style({ opacity: 1, offset: 1 })]))),
		]),
		trigger('in-out', [
			state('in', style({ opacity: 1 })),
			transition(':enter', [
				animate(
					300
					// keyframes([
					// 	style({ opacity: 0 }),
					// 	style({ opacity: 0.25 }),
					// 	style({ opacity: 0.5 }),
					// 	style({ opacity: 0.75 }),
					// 	style({ opacity: 1 }),
					// ])
				),
			]),
		]),
	],
	encapsulation: ViewEncapsulation.None,
})
export class NewchatComponent extends CommonComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('chatlistUL') chatlistUL: ElementRef;
	@ViewChild('chatQueryInput') chatQueryInput: ElementRef;
	@ViewChild('scrollerAnchor') scrollerAnchor: ElementRef;

	@ViewChild('benefitWrapper')
	benefitWrapperComponent: BenefitWrapperComponent;

	@T('Today')
	private readonly today: string;

	@T('Yesterday')
	private readonly yesterday: string;

	public chatlistObs: any;
	public zoeWrites: boolean;
	public disableSend: boolean;
	public isIonic = isIonic();
	public desktopView: boolean;
	private dialogInstance: any;
	public state: string = 'out';
	public userQueryInput: string;
	private convSub: Subscription;
	public mobileView: boolean = false;
	private screenSizeSub: Subscription;
	public showSidebar$: Observable<boolean>;
	public isRollingPausedByChatFooter: boolean;
	public isSuggestionsFeedOpen: boolean = false;
	public conversation$: Observable<ChatSession>;
	public maxQueryLength: number = MAX_QUERY_LENGTH;
	public feedIcon: string = ICONS_PATH.MARK_QUOTE_INITIAL;
	private querySent: Subject<ChatData> = new Subject<ChatData>();
	public readonly featureFlag_chat_liveFeed: boolean = FEATURES.chat_liveFeed;
	public readonly isChatHistoryFeatureEnabled = FEATURES.chatHistory;
	public isIntercomEnabled: boolean = false;
	public readonly PlanClassType = PlanClassType;
	private readonly feedbackButtons = [];
	public readonly DateBadge = DateBadge;
	public userLogData: ChatUserLogData = { _id: null, company: null, contracts: null };
	public isEnabledStagingBotConversation: boolean = this.unleashService.isEnabled(FfStagingBotConversation);
	public currEnv = environment.envName === 'local' ? 'production' : environment.envName;
	public userRole = UserRole.Employee;
	public botType: ConversationBot = this.currEnv as ConversationBot;
	public intercomConversationId: string = null;

	constructor(
		private router: Router,
		private languageService: LanguageService,
		private http: HttpClient,
		private dialog: MatDialog,
		private uiService: UIService,
		private sanitizer: DomSanitizer,
		private cdRef: ChangeDetectorRef,
		private appManager: AppManagerService,
		private trackingService: TrackingService,
		private conversationService: ConversationService,
		private _renderer2: Renderer2,
		private intercomService: IntercomService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private userService: UserService,
		private unleashService: UnleashService,
		private _providersSearchService: ProvidersSearchService,
		private telehealthDataService: TelehealthDataService,
		private telehealthService: TelehealthService,
		private allServicesStoreService: AllServicesStoreService,
	) {
		super();
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.checkForTelehealtService()
		if (this.unleashService.isEnabled(FeatureFlag.ChatGipitpot)) {
			setTimeout(() => {
				this.router.navigateByUrl('/gipitpot');
			}, 200);
		}

		this.subscribeToLocale();
		this.initSession(this.botType);
		this.setupSendMessage(this.botType);
		this.subscribeToScreenSize();
		this.subscribeToIntercom();
		this.subscribeToContract();
		this.subscribeToUserData();
	}

	public onBotRBChange(botType: ConversationBot) {
		this.botType = botType;
		this.conversationService.resetConversation();
		this.ngOnDestroy();
		this.ngOnInit();
	}

	private subscribeToUserData() {
		this.subsBag.add = this.userPlanDataStoreService
			.getContractsNames()
			.subscribe((contractsNames) => (this.userLogData.contracts = contractsNames));
		this.subsBag.add = this.userService.userData$
			.pipe(map((user) => ({ companyName: user.companyName, userId: user.uid, role: user.role })))
			.subscribe((userData) => {
				this.userRole = userData.role;
				(this.userLogData.company = userData.companyName), (this.userLogData._id = userData.userId);
			});
	}

	private subscribeToContract() {
		this.subsBag.add = this.userPlanDataStoreService.hasContract().subscribe((hasContract) => {
			this.disableSend = !hasContract;
		});
	}

	private subscribeToLocale() {
		this.subsBag.add = this.languageService.appLanguage$.pipe(take(1)).subscribe((appLanguage: AppLanguage) => {
			if (appLanguage.locale == 'es') this.router.navigate(['/gallery']);
		});
	}

	private subscribeToIntercom(): void {
		this.subsBag.add = this.intercomService.isEnabled$.subscribe((isEnable) => (this.isIntercomEnabled = isEnable));
	}

	ngAfterViewInit(): void {
		this.state = 'in';
		this.cdRef.detectChanges();
		this.chatQueryInput.nativeElement.focus();
		const targetNode = this.chatlistUL.nativeElement;

		const MutationObserver = window.MutationObserver;

		this.chatlistObs = new MutationObserver((mutations: any) => {
			const mutatedArr = mutations;

			for (const mutated of mutatedArr) {
				if (mutated.addedNodes.length > 1) {
					console.error("FLAG'S UP! UPDATE MUTATION OBS !!");
					return;
				}
				const mutatedElem = mutated.addedNodes[0];
				if (!mutatedElem) continue;

				let chooseFromListBtn = mutatedElem.getElementsByClassName('injected');
				const termBubbleBtn = mutatedElem.getElementsByClassName('bubble-wrapper');
				let termOptionBubbleBtn = mutatedElem.getElementsByClassName('option-bubble-wrapper');

				if (chooseFromListBtn.length > 0) {
					chooseFromListBtn = mutatedElem.getElementsByClassName('injected')[0];
					chooseFromListBtn.addEventListener('click', () => {
						this.openChooseFromListDialog();
					});
				}

				if (termBubbleBtn.length > 0) {
					for (let i = 0; i < termBubbleBtn.length; i++) {
						const bubbleBtn = termBubbleBtn[i];
						bubbleBtn.addEventListener('click', (openEvent) => {
							openEvent.stopPropagation();
							if (this.mobileView) {
								const emittingElement = openEvent.target.getElementsByClassName('desc')[0];
								const description = emittingElement?.innerText;
								const elementName = openEvent.target.getElementsByClassName('name')[0]?.innerText;
								this.openBubbleDialog(elementName, description);
							} else {
								const closeBtn = openEvent.target.getElementsByClassName('close-btn')[0];
								this._renderer2.addClass(openEvent.target, 'clicked');
								closeBtn.addEventListener('click', (closeEvent) => {
									closeEvent.stopPropagation();
									this._renderer2.removeClass(bubbleBtn, 'clicked');
								});
							}
							document.addEventListener('mouseup', (closeOutside) => {
								if (!bubbleBtn.contains(closeOutside.target)) {
									this._renderer2.removeClass(bubbleBtn, 'clicked');
								}
							});
						});
					}
				}
				if (termOptionBubbleBtn.length > 0) {
					termOptionBubbleBtn = mutatedElem.getElementsByClassName('option-bubble-wrapper')[0];
					termOptionBubbleBtn = termOptionBubbleBtn.getElementsByClassName('bubble-icon')[0]; // <i question> tag click event
					termOptionBubbleBtn.addEventListener('click', (ev) => {
						ev.stopPropagation();
						const emittingElement = ev.target.closest('div').getElementsByClassName('desc')[0];
						const elementName = ev.target.closest('div').getElementsByClassName('name')[0]?.innerText;
						const description = emittingElement.innerText;
						if (this.mobileView) this.openBubbleDialog(elementName, description);
					});
				}
			}
		});
		this.chatlistObs.observe(targetNode, { childList: true });
	}

	private initSession(botType: ConversationBot) {
		this.zoeWrites = true;
		this.conversation$ = this.conversationService.conversation$;

		if (!this.isChatHistoryFeatureEnabled) {
			this.subsBag.add = this.conversation$.pipe(take(1)).subscribe((conversation) => {
				if (!conversation.messages.length) {
					this.subsBag.add = this.conversationService.startSession(botType).subscribe((res) => {
						this.insertBotMsgToChat(res.welcomeMessage);
					});
				} else {
					this.scrollChatToBottom();
					this.zoeWrites = false;
				}
			});
			return;
		}

		const startSession = (botType: ConversationBot) =>
		(this.subsBag.add = this.conversation$.pipe(take(1)).subscribe(() => {
			if (!this.conversationService.isSessionInitialized) {
				this.subsBag.add = this.conversationService.startSession(botType).subscribe((res) => {
					this.insertBotMsgToChat(res.welcomeMessage);
				});
			} else {
				this.scrollChatToBottom();
				this.zoeWrites = false;
			}
			this.conversationService.isSessionInitialized = true;
		}));

		if (this.conversationService.isHistoryLoaded) {
			startSession(botType);
			return;
		}

		this.subsBag.add = this.conversationService.history().subscribe({
			next: (history) => {
				let zoeResponses = {
					generic: [],
				};
				const today = dayjs();
				const yesterday = today.subtract(1, 'day');
				const messages = history
					.reverse()
					.reduce((res, cur) => [...res, ...cur.msg], [])
					.filter((msg) => (msg.type === 'answer' ? !isNil(msg.originalObject) : true))
					.reduce(addDateBadges(today, yesterday, this.today, this.yesterday), []);

				messages.forEach((msg, index) => {
					if (msg.type === DateBadge || msg.type === 'question' || index === messages.length - 1) {
						if (zoeResponses.generic.length > 0) {
							this.insertBotMsgToChat(zoeResponses, true);
						}
					}
					if (msg.type === 'question') {
						const chatMsg = this.buildChatMessageFromUser(new Date(msg.created), msg.text);
						this.justPush(chatMsg);
						zoeResponses = {
							generic: [],
						};
						return;
					}
					if (msg.type === DateBadge) {
						this.justPush(msg);
						return;
					}
					zoeResponses.generic.push(msg.originalObject);
				});

				this.conversationService.isHistoryLoaded = true;
				startSession(botType);
			},
			error: () => startSession(botType),
		});
	}

	private subscribeToScreenSize() {
		this.screenSizeSub = this.uiService.appViewMode$.subscribe((size) => {
			this.mobileView = size === AppViewMode.Mobile;
			this.desktopView = !UIService.isMobileDeviceScreen(window);
		});
	}

	openBubbleDialog(name: string, desc: string) {
		this.dialog.open(BubbleDialogComponent, {
			data: { name: name, text: desc },
			panelClass: ['animate__animated', 'animate__bounceInUp', 'bubbledialog'],
		});
	}

	openBlockedDialog() {
		this.appManager.openBlockedAccountModal();
	}

	openChooseFromListDialog() {
		if (this.disableSend) {
			this.openBlockedDialog();
			return;
		}
		this.conversationService.openChooseFromList();
		this.trackingService.trackClientEvent('Chat with Zoe Choose from list open');

		this.subsBag.add = this.appManager.openChooseFromListModal().subscribe((entity) => {
			if (entity) {
				this.trackingService.trackClientEvent('Chat with Zoe Choose from list click', {
					input_text: entity.entity,
				});
				const text = entity.entity[0].toUpperCase() + entity.entity.slice(1);
				this.submitUserQuery(text, 'list', 'list');
			}
		});
	}

	goToCareAndCostByService(message) {
		const referrer = this.router.url;
		this._providersSearchService.goToProviderSearchWithServiceId(message.serviceId, referrer);
	}

	chooseOption(selectedOption: any, message: ChatMessage) {
		if(selectedOption.label === 'Connect Now'){
			this.telehealthService.onOpenTelehealth();
			this.trackingService.trackClientEvent('Telehealth modal started', {Source: 'Zoe'});
			return;
		}
		if (this.disableSend) {
			this.openBlockedDialog();
			return;
		}
		if (selectedOption.label === DOCTORS_SEARCH_TEXT) {
			return this.goToCareAndCostByService(message);
		}

		const chooseLabel = 'Choose From List';
		if (selectedOption.label !== chooseLabel && selectedOption.state !== undefined) return;

		const optionsArr = message.options || [];

		optionsArr.forEach((option: any) => {
			option.state = 'disabled';
		});
		selectedOption.state = 'active';
		this.trackingService.trackClientEvent('Chat with Zoe Button question', {
			input_text: selectedOption.value.input.text,
		});

		const selectedValue = selectedOption.value.input.text;
		if (selectedValue === 'Yes' || selectedValue === 'No')
			this.trackingService.trackClientEvent('Chat with Zoe Feedback click', { value: selectedValue });
		this.submitUserQuery(selectedOption.value.input.text, 'text', 'option', selectedOption.label);
	}

	chooseRelated(related: any, optionsArr: []) {

		this.trackingService.trackClientEvent('Chat with Zoe Button question', { input_text: related.title });

		optionsArr.forEach((option: any) => {
			option.state = 'disabled';
		});
		related.state = 'active';
		if (telehealthSerivceTitles.includes(related.title)) {
			this.trackingService.trackClientEvent('Telehealth free service chosen', {Source: 'Zoe'});

			const userMessage: ChatMessage = {
				owner: 'user',
				text: related.title,
				type: 'text'
			}
			this.sanitizeAndPush(userMessage)
			const prompt = {
				owner: 'bot',
				text: `Would you like to connect with a ${related.title.split('-')[0]} via Free Telehealth now?`,
				type: 'text'
			};

			this.sanitizeAndPush(prompt);

			const connectOption = {
				areOptionsClickable: true,
				entity: [],
				owner: 'bot',
				text: 'Connect Now',
				type: 'option',
				serviceId: undefined,
				showCoverage: true,
				title: 'Connect Now',
				options: [{ label: 'Connect Now', value: { input: { text: 'Connect Now' } } }]
			};

			this.sanitizeAndPush(connectOption);
			return
		}
		this.submitUserQuery(related.name, 'list', 'related_service', related.title);
	}

	insertBotMsgToChat(res: any, isFromHistory = false) {
		this.conversationService.setResponseFromZoe(res);
		const responses = res?.output ? res?.output.generic : res?.generic;
		let botResponse: ChatMessage;

		const firstMessage = {
			created: new Date(),
			type: 'avatar',
			isCovered: false,
			coverageStatus: res?.coverageStatus,
			serviceType: res?.serviceType,
		};

		const text = responses?.[0]?.text;

		if (text === undefined || !['<open-talon>', '<open-list>', '<connect-talon>'].includes(text)) {
			this.processAndPush(firstMessage, isFromHistory);
		}

		for (let i = 0; i < responses?.length; i++) {
			const generic = responses[i];

			botResponse = {};
			this.zoeWrites = false;
			botResponse.owner = 'bot';
			botResponse.entities = res.output ? res.output.entities : {};
			botResponse.showCoverage = true;

			if (generic.response_type === 'text') {
				const currentDate = new Date();
				const currentMins = currentDate.getMinutes();
				const time = currentDate.getHours() + ':' + (currentMins < 10 ? '0' + currentMins : currentMins);
				botResponse.time = time;
				botResponse.type = 'text';
				botResponse.text = generic.text;
				if (i !== 0) {
					botResponse.rounded = true;
				}
			}
			if (generic.response_type === 'option') {
				botResponse.type = 'option';
				botResponse.title = generic.title;
				botResponse.options = generic.options;

				this.feedbackButtons.forEach((button) => (button.state = 'disabled'));
				generic.options
					.filter(({ label }) => label === 'Yes' || label === 'No')
					.forEach((opt) => this.feedbackButtons.push(opt));

				botResponse.serviceId = generic.serviceId;
				botResponse.areOptionsClickable = true;
			}
			if (generic.response_type === 'insurightsObject') {
				const currentDate = new Date();
				const currentMins = currentDate.getMinutes();
				const time = currentDate.getHours() + ':' + (currentMins < 10 ? '0' + currentMins : currentMins);
				botResponse.time = time;
				botResponse.type = generic.type;
				botResponse.planType = generic.planType;
				if (generic.type === 'get_data') {
					botResponse.showCoverage = false;
					botResponse.title = generic.title;
					botResponse.notes = generic.notes;
					botResponse.iconURL = generic.iconURL;
					botResponse.related = generic.related;
					botResponse.serviceId = generic.serviceId;
					botResponse.inNetwork = generic.inNetwork;
					botResponse.outNetwork = generic.outNetwork;
					botResponse.hasRibbonData = generic.hasRibbonData;
					botResponse.networkStructure = generic.networkStructure;
					botResponse.blueOptionsNetwork = generic.blueOptionsNetwork;
					botResponse.additionalNetworks = generic.additionalNetworks;
					botResponse.inNetworkSubjectToDeductibles = generic.inNetworkSubjectToDeductibles;
					botResponse.outNetworkSubjectToDeductibles = generic.outNetworkSubjectToDeductibles;
					botResponse.blueOptionsNetworkSubjectToDeductibles = generic.blueOptionsNetworkSubjectToDeductibles;
					firstMessage.isCovered = true;
				}
				if (generic.planCareType === 'Open_acsess') {
					botResponse.planCareType = generic.planCareType;
				}
				if (generic.type === 'get_data_medical') {
					botResponse.related = generic.related;
				}
				if (generic.type === 'deductibles') {
					(botResponse as DeductibleChat).talonConnected = generic.talonConnected;
					(botResponse as DeductibleChat).contractType = generic.contractType;
					if (generic.talonConnected) (botResponse as DeductibleChat).talonData = generic.talonData;
					else {
						(botResponse as DeductibleChat).individual = generic.individual;
						(botResponse as DeductibleChat).family = generic.family;
						(botResponse as DeductibleChat).individualPlusOne = generic.individualPlusOne;
					}
					if (generic.outOfPocketMax) {
						(botResponse as DeductibleChat).outOfPocketMax = generic.outOfPocketMax;
					}
				}
			}
			this.processAndPush(botResponse, isFromHistory);
		}
	}

	processAndPush(chatNode: ChatMessage, isFromHistory = false) {
		if (chatNode.text?.indexOf('<nl>') > -1) {
			const texts = chatNode.text.split('<nl>');
			texts.forEach((text: string) => {
				const newNode = Object.assign({}, chatNode);
				newNode.text = text;
				this.sanitizeAndPush(newNode);
			});
			// } else if (chatNode.text?.indexOf("<focus-input>") > -1) {
			// 	this.chatQueryInput.nativeElement.focus();
		} else if (chatNode.text?.indexOf('<show-video>') > -1) {
			//TODO: design and impl
		} else if (chatNode.text?.indexOf('<open-talon>') > -1) {
			this.dialogInstance = this.dialog.open(TalonWidgetDialogComponent, {
				maxWidth: '',
				panelClass: ['talon-dialog-wrapper'],
			});

			this.subsBag.add = this.dialogInstance.afterClosed().subscribe((resumeWatson: boolean) => {
				if (!resumeWatson) return;
				const message = '<talon-closed>';
				const origin = '';
				this.submitUserQuery(message, origin);
			});
		} else if (chatNode.text?.indexOf('<open-list>') > -1) {
			if (isFromHistory) {
				return;
			}
			this.openChooseFromListDialog();
		} else if (chatNode.text?.indexOf('<connect-talon>') > -1) {
			this.appManager.openTalon(WidgetType.HEALTH_PLANS);
		} else {
			this.sanitizeAndPush(chatNode);
		}
	}

	sanitizeAndPush(msg: ChatMessage) {
		const isOption = msg.type === 'option';
		const sanitizedMsg: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(msg.text);
		if (isOption && this.mobileView) {
			//limit mobile view to 4 crosses max
			msg.options = msg.options.splice(0, 4);
		}
		msg.text = sanitizedMsg;

		this.scrollChatToBottom();

		this.conversationService.pushChatMessageToConversation(msg);
	}

	justPush(msg: ChatMessage) {
		this.scrollChatToBottom();
		this.conversationService.pushChatMessageToConversation(msg);
	}

	private buildChatMessageFromUser(created: Date, text: string): ChatMessage {
		const res: ChatMessage = {
			created,
			owner: 'user',
			text,
			type: 'text',
		};
		if (res.text.length > 0 && !res.text.includes('talon-closed')) {
			res.text = this.sanitizer.bypassSecurityTrustHtml(res.text);
		}
		return res;
	}

	submitText() {
		if (this.disableSend) {
			this.openBlockedDialog();
			return;
		}

		if (this.userQueryInput) {
			this.trackingService.trackClientEvent('Chat with Zoe Free text question', {
				input_text: this.userQueryInput,
			});
		}
		this.submitUserQuery(this.userQueryInput, 'text', 'free_text');
	}

	submitUserQuery(
		userQuery: string,
		origin: string,
		analyticsOrigin: string = null,
		title: string = null,
		init: boolean = false
	): void {
		if (!userQuery && !init) {
			return; //Temp fix to empty messages issue
		}
		this.zoeWrites = true;
		const userMsg: ChatData = {
			message: userQuery,
			origin,
			analyticsOrigin,
		};

		const text = title || userQuery;
		const userQueryObj = this.buildChatMessageFromUser(new Date(), text);
		if (userQuery.length > 0 && !text.includes('talon-closed')) {
			this.justPush(userQueryObj);
		}
		this.userQueryInput = '';
		this.querySent.next(userMsg);
	}

	private setupSendMessage(botType: ConversationBot) {
		this.subsBag.add = this.querySent
			.pipe(
				debounceTime(CFG.behavior.chatDebounceDuration),
				distinctUntilChanged(),
				switchMap((userMsg: ChatData) => this.conversationService.sendMessageData(userMsg, botType)),
				switchMap((chatResponseWithUserInput: ChatResponseWithUserInput): Observable<ChatAndIntercomResponse> => {
					if (!chatResponseWithUserInput.shouldTriggerLiveExpert)
						return of({ chatResponseWithUserInput, intercomConversationId: null });

					return this.conversationService
						.escalateChatToLiveExpert(this.intercomConversationId, chatResponseWithUserInput.userInput)
						.pipe(
							tap(intercomResponse => console.log('Live expert flow initiated successfully with conversationId: ', intercomResponse.conversationId)),
							map(intercomResponse => ({ chatResponseWithUserInput, intercomConversationId: intercomResponse.conversationId }))
						);
				}),
				catchError((error) => this.handleSendZoeMessageError(error)),
			)
			.subscribe({
				next: (zoeAndIntercomResponse: ChatAndIntercomResponse) => {

					this.zoeWrites = false;

					if (zoeAndIntercomResponse.intercomConversationId) {
						this.intercomConversationId = zoeAndIntercomResponse.intercomConversationId;
						this.intercomService.openConversation(this.intercomConversationId);
						this.conversationService.clearIntercomConversation();
					}

					this.insertBotMsgToChat(zoeAndIntercomResponse.chatResponseWithUserInput);
					this.logZoeMessage(zoeAndIntercomResponse.chatResponseWithUserInput, zoeAndIntercomResponse.chatResponseWithUserInput.userInput);
				}
			});
	}

	private handleSendZoeMessageError(error: Error) {
		this.zoeWrites = false;
		return this.http.post(CFG.apiEndpoints.usersLog, {
			error: '*** client error in message: ' + error.message,
		});
	}

	msgWrapperStyle(message: ChatMessage): string {
		return message.rounded ? 'rounded ' + message.owner : ' ' + message.owner;
	}

	public sendToChatFromFeed(text: string): void {
		this.submitUserQuery(text, 'feed');
	}

	public closeFeed(): void {
		this.isSuggestionsFeedOpen = false;
		this.feedIcon = ICONS_PATH.MARK_QUOTE_INITIAL;
	}

	public feedIconAction(): void {
		if (this.isSuggestionsFeedOpen) {
			this.switchFeedStatus();
		} else {
			this.isSuggestionsFeedOpen = true;
			this.feedIcon = ICONS_PATH.STOP;
		}
	}

	private switchFeedStatus(): void {
		this.feedIcon = this.isRollingPausedByChatFooter ? ICONS_PATH.STOP : ICONS_PATH.PLAY;
		this.isRollingPausedByChatFooter = !this.isRollingPausedByChatFooter;
	}

	private async scrollChatToBottom() {
		await delay(100);
		const element = this.scrollerAnchor.nativeElement;
		element.scrollIntoView(true);
	}

	public showCoverage(message) {
		message.disable_show_coverage = true;
		const newMessage = Object.assign({}, message);
		newMessage.type = 'coverage';
		this.sanitizeAndPush(newMessage);
	}

	public openAddressBarDialog(message): void {
		this.subsBag.add = this.appManager
			.openAddressBarDialog(message.serviceId)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					if (res?.ribbonProviders) {
						const addressString = res.query || 'the address';
						const searchString = message.entities[0]?.value || 'your search';

						const userQueryObj: ChatMessage = {
							created: new Date(),
							owner: 'user',
							text: addressString,
							type: 'text',
						};

						this.sanitizeAndPush(userQueryObj);

						const avatar = {
							created: new Date(),
							type: 'avatar',
							isCovered: false,
						};

						this.sanitizeAndPush(avatar);

						const zoeResponse: ChatMessage = {
							created: new Date(),
							text: `Here are the <strong>${searchString}s</strong> I found near <strong>${addressString}</strong>.`,
							type: 'text',
						};
						this.sanitizeAndPush(zoeResponse);

						const msgObj: ChatMessage = {
							created: new Date(),
							owner: 'zoe',
							type: 'ribbon_results',
							msg: res,
						};
						this.sanitizeAndPush(msgObj);
						const wMessage = Object.assign({}, message);
						wMessage.type = 'change-location';
						this.sanitizeAndPush(wMessage);
						message.disable_location_picker = true;
					}
				},
			});
	}

	private logZoeMessage(zoeResponse: ChatResponse, userInput: string): void {
		if (!zoeResponse) return;
		const logObj = {
			userId: this.userLogData._id,
			company: this.userLogData.company,
			userInput: userInput,
			chatOutput: zoeResponse.output?.generic[0]?.text || zoeResponse.output?.generic[0]?.title,
			environment: environment.envName,
			userContracts: this.userLogData.contracts,
			flowType: zoeResponse.flow_type,
			coverageStatus: zoeResponse.coverageStatus || 'No coverage',
			intent: zoeResponse.output?.intents?.map((intent) => intent.intent),
			entities: zoeResponse.output?.entities?.map((entity) => entity.value),
		};
		this.trackingService.trackClientEvent('[ZOE_ANSWER]', logObj);
	}

	private checkForTelehealtService() {
		combineLatest([this.conversationService.conversation$, this.allServicesStoreService.get().pipe(RxjsUtils.isNotNil()), this.telehealthDataService.getTelehealthFreeServices()]).pipe(map(([c, s, f]) => {
			const serviceMessage = c.messages.filter(m => m.type === 'get_data').pop()
			if (serviceMessage) {
				const isTelehealthLinkedService = this.telehealthDataService.isServiceLinkedWithTelehealth(serviceMessage.serviceId);
				if (isTelehealthLinkedService) {
					const linkedServiceName = this.telehealthDataService.getLinekdServiceName(serviceMessage.serviceId);
					const linkedServiceAlreadyExists = serviceMessage.related.find(s => s.title === linkedServiceName)

					if (!linkedServiceAlreadyExists) {
						serviceMessage.related.unshift({ title: linkedServiceName, iconURL: '', name: linkedServiceName })
					}
				}
				else if(serviceMessage.serviceId === telehealthSerivcesIDFromDB){

					f.forEach(service => {
						const exists = serviceMessage.related.find(s => s.title.toLocaleLowerCase() === service.name.toLocaleLowerCase())
						if(!exists){
							serviceMessage.related.unshift({ title: (service as any).abbreviate, iconURL: '', name: (service as any).abbreviate })
						}

					})
				}
			}

		})).subscribe()
	}

	public processLinks(e: any) {
		const element: HTMLElement = e.target;
		if (element.nodeName === 'A') {
			const link = element.getAttribute('href');
			// If we have a relative link do the client side navigation
			if (link.startsWith("/")) {
				e.preventDefault();
				this.router.navigate([link]);
			}
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.chatlistObs.disconnect();
		this.convSub?.unsubscribe();
		this.screenSizeSub?.unsubscribe();
	}
}

export interface ChatBenefit {
	title: string;
	iconURL: string;
	inNetwork: string;
	outNetwork: string;
	inNetworkSubjectToDeductables: boolean;
	outNetworkSubjectToDeductables: boolean;
	notes: [string];
	related: [
		{
			title: string;
			iconURL: string;
		}
	];
}

<app-digital-card #cardElement [contract]="contract">
	<div content class="card-content">
		<div class="values-row">
			<div class="label-and-value">
				<div class="label">{{ 'Subscriber Name' | translate }}</div>
				<div class="value fs-mask">{{ fullName }}</div>
			</div>
			<div class="label-and-value right">
				<div class="label">{{ 'Member ID' | translate }}</div>
				<div class="value fs-mask">{{ memberId$ | async }}</div>
			</div>
		</div>
		<div class="values-row">
			<div class="label-and-value">
				<div class="label">{{ 'Group Number' | translate }}</div>
				<div class="value">{{ contract?.groupNumber || 'N/A' }}</div>
			</div>
			<div class="label-and-value right">
				<div class="label">{{ 'Date of birth' | translate }}</div>
				<div class="value">{{ dob$ | async }}</div>
			</div>
		</div>
		<div class="label-and-value name">
			<div class="label">{{ 'Plan Name' | translate }}</div>
			<div class="name">{{ contract?.name }}</div>
		</div>
		<mat-expansion-panel
			class="member-card-costs-panel"
			[(expanded)]="isExpanded"
			hideToggle="true"
			(opened)="setButtonValues()"
			(closed)="setButtonValues()"
		>
			<mat-expansion-panel-header>
				<div class="non-expansion" (click)="trackShowCosts()">
					<button class="bt secondary-blue" *ngIf="(benefits$ | async)?.length > 0">
						{{ buttonText | translate }}
						<ripple-icon [name]="iconName"></ripple-icon>
					</button>
					<div class="buttons-wrapper">
						<div class="full-card-edit" (click)="edit($event)">
							<ripple-icon name="editOutline"></ripple-icon>
						</div>
						<div>
							<mat-icon
								[class.inaccessible]="shareLoadingHandler.isLoading$ | async"
								(click)="share($event)"
								class="full-card-share"
								>ios_share</mat-icon
							>
						</div>
					</div>
				</div>
			</mat-expansion-panel-header>
			<div class="expansion">
				<div class="label-and-value" *ngFor="let benefit of benefits$ | async">
					<div class="label">{{ benefit.title }}</div>
					<div class="value">
						<ng-container>{{ getBenefitCostValue(benefit) }}</ng-container>
					</div>
				</div>
				<div
					class="disclaimer"
					*ngIf="
						(isMedicalCardDisclaimerEnabled$ | async) && allBenefitsSubjectToDeductible(benefits$ | async)
					"
				>
					<div class="headline">Note</div>
					<div class="description">The co-pay costs above apply only after meeting your deductible</div>
				</div>
			</div>
		</mat-expansion-panel>
	</div>
</app-digital-card>

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest, filter } from 'rxjs';
import { isNil } from 'projects/ripple/src/lib/utils';

import appConfig from '../../../../config/app-config.json';
import { RxjsUtils } from '../../../../utils/rxjs';
import {
	AuthFields,
	SearchEntity,
	SearchFields,
	SearchType,
	isValidAuthRedirectUrl,
	isValidSearchParams,
	retrieveValueByEntity,
} from '../helpers/providers-search.helper';
import { NetworkStructure } from '../../../../models/chat.model';
import { HealtheeService } from '../../../../models/service.model';
import { AppointmentFetch } from '../../../../models/appointment.model';
import { CommonComponent } from '../../../../utils/components/common-component';
import { FfProvidersSearchEmptyState } from '../../../../config/feature-flags/ff-providers-search-empty-state';

import { UIService } from '../../../../services/ui.service';
import { ProvidersSearchUrlsService } from '../../../../services/providers-search/providers-search-urls.service';
import { SearchBarService } from '../../../../services/providers-search/search-bar.service';
import { RibbonEntitiesStoreService } from '../../../../services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import {
	ProvidersSearchService,
	searchBarParamsToServerParams,
} from '../../../../services/providers-search/providers-search.service';
import { UnleashService } from '../../../../services/unleash.service';
import { ProvidersApiService } from '../../../../services/api/providers-api/providers-api.service';
import { ProvidersAppointmentService } from '../../../../services/providers-search/providers-appointment.service';
import { LoggerService } from '../../../../services/logger.service';

@Component({
	selector: 'app-providers-search-page',
	templateUrl: './providers-search-page.component.html',
	styleUrls: ['./providers-search-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProvidersSearchPageComponent extends CommonComponent implements OnInit, OnDestroy {
	public networkStructure$: Observable<NetworkStructure> = this.userPlanDataStoreService.getPlansNetworkStructure();
	public selectedFilters = this.searchBarService.getFiltersFormGroup().value;
	public selectedService: HealtheeService;
	public resetFiltersToggle = false;
	public isEmptyStateEnabled$ = this.unleashService.isEnabled$(FfProvidersSearchEmptyState);
	public showMobileMap = false;
	public isEmptyStateEnabled = null;
	public activeAppointments$: Observable<AppointmentFetch[]> = this.providersAppointmentService.getAppointments();

	private authRedirectUrlSub: Subscription;

	constructor(
		private uiService: UIService,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private searchBarService: SearchBarService,
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private providersSearchService: ProvidersSearchService,
		private route: ActivatedRoute,
		private unleashService: UnleashService,
		private providersAppointmentService: ProvidersAppointmentService,
		private providersApiService: ProvidersApiService,
		private logger: LoggerService
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.authRedirectUrlSub = combineLatest([
			this.isEmptyStateEnabled$,
			this.activeAppointments$,
			this.route.queryParams
		])
			.pipe(
				filter(
					([isEmptyStateEnabled, activeAppointments]) =>
						!isNil(isEmptyStateEnabled) && !isNil(activeAppointments)
				)
			)
			.subscribe({
				next: ([isEmptyStateEnabled, activeAppointments, queryParams]) => {
					this.logger.debugOnDevice(
						'ProvidersSearchPageComponent: inside [isEmptyStateEnabled, activeAppointments] subscriber',
						{ isEmptyStateEnabled, activeAppointments, queryParams }
					);

					if (isValidAuthRedirectUrl(queryParams)) {
						localStorage.setItem('pkce_code', queryParams.code);
						const stateFromStorage = localStorage.getItem(
							appConfig.localStorageKeys.zocdocRedirectStateKey
						);
						localStorage.removeItem(appConfig.localStorageKeys.zocdocRedirectStateKey);
						if (stateFromStorage) {
							this.uiService.navigateByUrl(stateFromStorage);
						} else {
							this.uiService.navigate([this.providersSearchUrlsService.baseUrl]);
						}
					}
					this.isEmptyStateEnabled = isEmptyStateEnabled && activeAppointments?.length === 0;
					if (this.isEmptyStateEnabled) {
						this.subsBag.add = combineLatest([
							this.route.queryParams,
							this.ribbonEntitiesStoreService.get().pipe(RxjsUtils.isNotNil()),
							this.networkStructure$,
						]).subscribe({
							next: ([queryParams, ribbonEntities, networkStructure]: [
								SearchFields & AuthFields,
								any,
								NetworkStructure
							]) => {
								if (!isValidSearchParams(queryParams)) {
									return;
								}

								const customFieldValue =
									queryParams.searchType === SearchType.Options
										? retrieveValueByEntity(queryParams.value, queryParams.entity, ribbonEntities)
										: queryParams.value;
								const searchParams = searchBarParamsToServerParams(
									queryParams,
									customFieldValue,
									networkStructure
								);
								this.providersSearchService.search(searchParams, queryParams);
							},
						});
					}
				},
			});
	}

	public setServiceForEmptyState(service) {
		this.selectedService = service;
		this.searchProviders(service, this.selectedFilters);
	}

	public setService(service) {
		this.selectedService = service;
	}

	public setFilters(filters) {
		this.selectedFilters = filters;
		this.searchProviders(this.selectedService, filters);
	}

	public onSearch(queryParams: { entity: SearchEntity; searchType: SearchType }) {
		this.providersApiService.searchByParams(queryParams, 'merge');
	}

	private searchProviders(service, filters) {
		this.uiService.navigate([this.providersSearchUrlsService.baseUrl], {
			queryParams: { ...service, ...filters },
			skipLocationChange: true,
		});
	}

	public resetFiltersHandler() {
		this.resetFiltersToggle = !this.resetFiltersToggle;
	}

	ngOnDestroy(): void {
		this.authRedirectUrlSub?.unsubscribe();
	}
}
